import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& .MuiTextField-root': {
      border: 0,
      marginBottom: '20px',
      width: '25ch',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px',
    },
  },
  filterInput: {
    marginBottom: '0 !important',
    paddingLeft: '10px !important',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '0 !important',
    paddingBottom: '0 !important',
  },
}));

const RegistrosFilter = props => {
  const classes = useStyles();
  const { config } = props;

  const renderFilters = () => {
    return (
      <Grid item xs={12}>
        <Grid
          spacing={2}
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
        >
          {config.map(currentFilter => (
            <Grid
              item
              xs={currentFilter.responsiveSize.xs}
              sm={currentFilter.responsiveSize.sm}
              className={classes.grid}
              key={`input-${currentFilter.inputId}`}
            >
              <TextField
                className={classes.filterInput}
                id={currentFilter.inputId}
                variant="outlined"
                placeholder={currentFilter.label}
                value={currentFilter.value}
                onChange={async event => {
                  if (
                    typeof currentFilter.action ===
                    'function'
                  ) {
                    await currentFilter.action(event);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={2}>
        {renderFilters()}
      </Grid>
    </React.Fragment>
  );
};

RegistrosFilter.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
      responsiveSize: PropTypes.shape({
        xs: PropTypes.number.isRequired,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
      }),
      inputId: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default RegistrosFilter;
