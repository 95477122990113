function createData(
  consecutivo,
  cedula,
  primerApellido,
  segundoApellido,
  nombre,
  estado,
  id,
) {
  return {
    consecutivo,
    cedula,
    primerApellido,
    segundoApellido,
    nombre,
    estado,
    id,
  };
}

export const tableHeaders = [
  {
    name: 'Consecutivo',
  },
  {
    name: 'Cédula',
  },
  {
    name: '1er. Apellido',
  },
  {
    name: '2do. Apellido',
  },
  {
    name: 'Nombre',
  },
  {
    name: 'Estado',
  },
];

export const dataRows = [
  createData(
    1,
    '40190001',
    'Sequeira',
    'Valverde',
    'Sonia',
    'Preinscrita',
    2560,
  ),
  createData(
    2,
    '401900012',
    'Calvo',
    'Solorzano',
    'Marta',
    'Preinscrita',
    2561,
  ),
];
