import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import { useJwt } from 'react-jwt';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Logout from '../../Logout/Logout';

import MenuItems from '../../Dashboard/MenuItems';
import BalanceContext from '../../../Core/Context/AppContext';
import Storage from '../../../Storage/Storage';

const AppBarCustom = props => {
  const [open, setOpen] = useState(true);
  const { classes } = props;

  const tokenStorage = Storage.instance;

  const { decodedToken } = useJwt(tokenStorage.getToken());

  const { balance, getBalanceAPI } = useContext(
    BalanceContext,
  );

  const [globalBalance, setGlobalBalance] = useState(
    balance,
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function fetchBalanceAPI() {
      if (decodedToken)
        await getBalanceAPI(decodedToken.num_cedula);
    }

    fetchBalanceAPI();
  }, [decodedToken]);

  useEffect(() => {
    if (balance) setGlobalBalance(balance);
  }, [balance]);

  return (
    <React.Fragment>
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          open && classes.appBarShift,
        )}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            PLATAFORMA DE ELECCIONES DISTRITALES
          </Typography>
          <Chip
            label={`Depósitos: ¢ ${
              globalBalance ? globalBalance.deposits : 0.0
            }`}
            color="primary"
            className={classes.customChip}
          />
          <Chip
            label={`Membresías: ¢ ${
              globalBalance
                ? globalBalance.memberships
                : 0.0
            }`}
            color="primary"
            className={classes.customChip}
          />
          <Chip
            label={`Inscripiciones: ¢ ${
              globalBalance
                ? globalBalance.inscriptions
                : 0.0
            }`}
            color="primary"
            className={classes.customChip}
          />

          <Chip
            label={`Saldo: ¢ ${
              globalBalance ? globalBalance.saldo : 0.0
            }`}
            color="primary"
            className={classes.customChip}
          />
          <Logout />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
          ),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img
            src="/logo2020.png"
            className={classes.imgLogo}
            alt="LogoPLN"
          />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuItems />
        <Divider />
      </Drawer>
    </React.Fragment>
  );
};

export default AppBarCustom;
