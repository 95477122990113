function createData(
  id,
  provincia,
  canton,
  movimiento,
  numero,
  estado,
  edit,
) {
  return {
    id,
    provincia,
    canton,
    movimiento,
    numero,
    estado,
    edit,
  };
}

export const tableHeaders = [
  {
    name: 'Consecutivo',
  },
  {
    name: 'Provincia',
  },
  {
    name: 'Cantón',
  },
  {
    name: 'Movimiento',
  },
  {
    name: 'Número',
  },
  {
    name: 'Estado',
  },
];

export const dataRows = [
  createData(
    1,
    'Heredia',
    'Central',
    'Mujeres',
    8,
    'Preinscrita',
  ),
  createData(
    2,
    'Heredia',
    'Central',
    'Trabajadores',
    8,
    'Preinscrita',
  ),
  createData(
    3,
    'Heredia',
    'Central',
    'Juventud',
    8,
    'Preinscrita',
  ),
  createData(
    4,
    'Heredia',
    'Central',
    'Cooperativo',
    8,
    'Anulada',
  ),
  createData(
    5,
    'Heredia',
    'Sto Domingo',
    'Mujeres',
    6,
    'Preinscrita',
  ),
  createData(
    6,
    'Heredia',
    'Sto Domingo',
    'Trabajadores',
    6,
    'Preinscrita',
  ),
  createData(
    7,
    'Heredia',
    'Sto Domingo',
    'Juventud',
    6,
    'Preinscrita',
  ),
  createData(
    8,
    'Heredia',
    'Sto Domingo',
    'Cooperativo',
    6,
    'Preinscrita',
  ),
];
