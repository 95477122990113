import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import { makeStyles } from '@material-ui/core/styles';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';
import Storage from '../../Storage/Storage';

import {
  getAll,
  deleteDepositByCod,
} from '../../Core/Api/depositosApi';
import { formatData } from '../../Core/Utils/Utils';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';
import { useCRUDAvailable } from '../../Core/Api/periodoApi';

function createData(_data) {
  return {
    numDeposito: _data.num_deposito,
    montoDeposito: `${formatData(_data.num_monto)}`,
    fecha: _data.fec_deposito,
    tipoDeposito:
      _data.ind_deposito === 0
        ? 'Membresía'
        : 'Inscripción',
    ced_depositante: _data.ced_depositante,
    nombreDepositante: `${_data.des_nombre} ${_data.des_apell1} ${_data.des_apell2}`,
    id: _data.cod_deposito,
  };
}

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const RegistroDepositos = () => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );

  const classes = useStyles();
  const tokenStorage = Storage.instance;

  const [items, setItems] = useState([]);
  const [isDataEmpty, setDataEmpty] = useState(false);

  const { decodedToken } = useJwt(tokenStorage.getToken());

  const [open, setOpenDialog] = React.useState(false);
  const [dataRemove, setDataRemove] = React.useState(null);
  const [isRemoved, setIsRemoved] = useState(false); // used on remove modal
  const [removedMsj, setRemoveMsj] = useState(
    'El registro se eliminará permanentemente. ¿Desea proceder?',
  );
  const [hiddenBtn, setHiddenBtn] = useState(false);

  useEffect(() => {
    async function fetchListAPI() {
      if (decodedToken) {
        await getAll(decodedToken.num_cedula)
          .then(_response => {
            const info = _response.data.response;

            if (info instanceof Object) {
              const rowItems = info.map(row => {
                return createData(row);
              });

              setItems(rowItems);
              // setFilteredItems(rowItems);
              setDataEmpty(false);
            } else {
              setDataEmpty(true);
            }
          })
          .catch(_error => {
            // eslint-disable-next-line no-console
            console.log(_error);
          });
      }
    }

    fetchListAPI();
  }, [decodedToken]);

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    if (crudAvailable) {
      const actionEdit = {
        aria: 'Editar Depósito',
        key: 'edit',
        showAction: true,
        icon: (
          <EditIcon
            fontSize="inherit"
            style={{ color: '#84BF48' }}
          />
        ),
        clickHandler: _item => {
          window.location.href = `/registro-depositos/edit/${_item.id}`;
        },
      };

      actions.push(actionEdit);

      const actionRemove = {
        aria: 'Eliminar Depósito',
        key: 'remove',
        showAction: true,
        icon: (
          <DeleteSharpIcon
            fontSize="inherit"
            style={{ color: '#cc0000' }}
          />
        ),
        clickHandler: _item => {
          setDataRemove(_item);
          setOpenDialog(true);
        },
      };

      actions.push(actionRemove);
    }
    return actions;
  };

  /**
   * Remove a member selected by user
   */
  const removeDepositByNum = async () => {
    const codDeposit = dataRemove.id;

    if (codDeposit && codDeposit > 0) {
      setIsRemoved(true);

      await deleteDepositByCod(codDeposit)
        .then(_rs => {
          const responseInfo = _rs.data;

          const configCallback = {
            isRemoved: true,
            isHiddenBtn: true,
            message:
              'La operación no se pudo completar. Intente de nuevo.',
          };

          if (
            responseInfo.response === 1 &&
            responseInfo.row.length === 1 &&
            _rs.status === 200
          ) {
            Object.assign(configCallback, {
              isRemoved: false,
              isHiddenBtn: true,
              message: 'Depósito eliminado correctamente.',
            });
          }

          setIsRemoved(configCallback.isRemoved);
          setHiddenBtn(configCallback.isHiddenBtn);
          setRemoveMsj(configCallback.message);
          setTimeout(() => {
            setOpenDialog(false);
            window.location.href = '/registro-depositos/';
          }, 3000);
        })
        .catch(error => {
          console.warn(error);
        });
    }
  };

  return (
    <>
      <MacroList
        dataEmpty={isDataEmpty}
        listName="Lista de Depósitos Realizados"
        uiStylesClasses={classes}
        btnConfig={{
          name: '+ Nuevo Depósito',
          event: () =>
            (window.location.href = `/registro-depositos/add`),
        }}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={items}
        showTotals
        isCrudAvailable={crudAvailable}
      />
      <PLNDialog
        open={open}
        onClose={() => {}}
        title="Eliminar Registro Depósito"
        bodymessage={removedMsj}
        buttons={
          hiddenBtn
            ? []
            : [
                {
                  actionName: 'cancel',
                  color: 'simple',
                  title: 'Cerrar',
                  clickHander: () => {
                    setOpenDialog(!open);
                  },
                },
                {
                  actionName: 'delete',
                  disabled: isRemoved,
                  color: 'remove',
                  title: isRemoved ? (
                    <CircularProgress
                      style={{
                        width: '28px',
                        height: '24px',
                      }}
                    />
                  ) : (
                    'Eliminar'
                  ),
                  clickHander: removeDepositByNum,
                },
              ]
        }
      />
    </>
  );
};

export default RegistroDepositos;
