/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  PlnDropdown,
  PlnInput,
} from '../../Components/Input';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Consts from '../../Core/Consts/Consts';
import Copyright from '../Copyright/Copyright';

import { useSectores } from '../../Core/Api/locationApi';

import {
  searchPapeletas,
  getDetallePapeleta,
} from '../../Core/Api/papeletaSectorial';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

function createData(_data) {
  const filterEstado = Consts.estadosDistritales.filter(
    status => {
      return status.codigo === _data.cod_estado;
    },
  );

  return {
    sector: _data.des,
    numero: _data.numero,
    cod_estado: filterEstado[0].estado,
    cedula: _data.cedula_resp,
    gestor: _data.des_nombre,
    cod_papeleta: _data.cod_papeleta,
  };
}

const CPPapeletasSectoriales = props => {
  const { classes } = props;
  const listSectores = useSectores();

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas
  const sectores = Lodash.get(
    listSectores,
    ['data', 'frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE EL SECTOR -',
        value: 9,
      },
    ],
  );

  // INFORMACION DEL DETALLE DE LA PAPELETA
  const [rowSelected, setRowSelected] = useState({
    sector: '',
    numero: '',
  });

  const [comite, setComite] = useState([]);

  const [isDataEmpty, setDataEmpty] = useState(false);
  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', ''],
  ]); // used for filtering

  const [openPapeleta, setOpenPapeleta] = useState(false);
  const handleClose = () => {
    setOpenPapeleta(false);
  };

  const heads = [
    {
      name: 'Sector',
    },
    {
      name: 'Número',
    },
    {
      name: 'Estado',
    },
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre Gestor(a)',
    },
  ];

  // Lista de: Sector y Numero
  const [initialValues] = useState({
    sector: 9,
    numero: '',
  });

  const validationSchema = Yup.object({});

  const setSector = d => {
    initialValues.sector = d;
  };

  const doSearch = values => () => {
    setShowResults(true);
    setIsLoading(true);
    const { sector, numero } = values;
    const codSector = sector === 9 ? -1 : sector;
    const numPapeleta = numero === '' ? -1 : numero;

    searchPapeletas(codSector, numPapeleta).then(result => {
      setIsLoading(false);
      const info = Lodash.get(
        result,
        ['data', 'response'],
        [],
      );

      if (info instanceof Object && info.length > 0) {
        const rowItems = info.map(row => {
          return createData(row);
        });
        setFilteredItems(rowItems);
        setDataEmpty(false);
      } else {
        setFilteredItems([]);
        setDataEmpty(true);
      }
    });
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h4>Sectores</h4>
            <Field
              name="sector"
              data={sectores}
              component={PlnDropdown}
              onChange={sector => setSector(sector)}
            />
          </Grid>
          <Grid item xs={6}>
            <h4>Número</h4>
            <Field
              mask="999999999"
              name="numero"
              component={PlnInput}
              type="text"
              style={{ marginTop: 10, height: 60 }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const consultar = (sector, numero, codPapeleta) => {
    const newRow = {
      ...rowSelected,
      sector,
      numero,
    };

    getDetallePapeleta(codPapeleta).then(result => {
      setRowSelected(newRow);

      const nComite = Lodash.get(
        result,
        ['data', 'response', 'comite'],
        [],
      );

      setComite(nComite);
      setOpenPapeleta(true);
    });
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    const actionConsultar = {
      aria: 'Consultar',
      key: 'consultar',
      showAction: true,
      icon: (
        <VisibilityIcon
          fontSize="default"
          style={{ color: '#008945' }}
        />
      ),
      clickHandler: _item => {
        consultar(
          _item.sector,
          _item.numero,
          _item.cod_papeleta,
        );
      },
    };

    actions.push(actionConsultar);

    return actions;
  };

  const renderTable = () => {
    return (
      <div>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          className={classes.sectionTitle}
          style={{
            textAlign: 'center',
            marginTop: 15,
          }}
        >
          Candidatos(as) Representante al Órgano Consultivo
          Nacional
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>Lugar</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Cédula</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Nombre</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comite.map(l => (
              <TableRow key={`delegado-${l[0]}`}>
                <TableCell align="center">
                  <strong>{l[0]}</strong>
                </TableCell>
                <TableCell align="center">{l[1]}</TableCell>
                <TableCell align="center">{l[2]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3>CONSULTA PÚBLICA DE INSCRIPCIONES</h3>
      <h4 className={classes.subtitle}>
        Papeletas de Sectores
      </h4>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {formik => (
          <>
            {renderForm()}
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={doSearch(formik.values)}
                  style={{ marginTop: 10 }}
                >
                  BUSCAR
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>

      {showResults &&
        (!isLoading ? (
          <MacroList
            dataEmpty={isDataEmpty}
            listName=""
            uiStylesClasses={classes}
            btnConfig={{
              name: '+ Registro',
              event: () =>
                (window.location.href = `/sectorial/add`),
            }}
            actionConfig={setActions}
            tableHeads={heads}
            tableBody={filteredItems}
            displayCopyright={false}
            isCrudAvailable={false}
          />
        ) : (
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{ textAlign: 'center' }}
            >
              <CircularProgress
                style={{
                  width: '28px',
                  height: '24px',
                }}
              />
            </Grid>
          </Grid>
        ))}

      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="lg"
        open={openPapeleta}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          color="warning"
          style={{ textAlign: 'center' }}
        >
          Detalle Papeleta de Sectores
        </DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Sector</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Número</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {rowSelected.sector}
                </TableCell>
                <TableCell align="center">
                  {rowSelected.numero}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {renderTable()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className={classes.btnActions}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

CPPapeletasSectoriales.propTypes = {};

export default withStyles(style)(CPPapeletasSectoriales);
