import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    responseType: 'json',
});

const getFilteredMiembrosMesa = info => {
    const data = [info.codProvincia, info.codCanton, info.codDistrito, info.codJRV],
        url = [`/registrosElectorales/filterMiembrosMesa/${data.join('/')}`];
    return axiosInstance.get(url.join('&'));
};

const getFilteredFiscalesMesa = info => {
    const data = [info.codProvincia, info.codCanton, info.codDistrito, info.codJRV],
        url = [`/registrosElectorales/filterFiscalesMesa/${data.join('/')}`];
    return axiosInstance.get(url.join('&'));
};

const getFilteredFiscalesGenerales = info => {
    const data = [info.codProvincia, info.codCanton, info.codDistrito, info.codJRV],
        url = [`/registrosElectorales/filterFiscalesGenerales/${data.join('/')}`];
    return axiosInstance.get(url.join('&'));
};

export default {
    getFilteredMiembrosMesa,
    getFilteredFiscalesMesa,
    getFilteredFiscalesGenerales,
};