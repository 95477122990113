/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import useStyles from '../Containers/Layout/makeStyles';
import Header from '../Containers/Header/Header';
import AppBarCustom from '../Containers/Layout/AppBarCustom/AppBarCustom';
import AuthMiddleware from '../Middleware/AuthMiddleware';

const authMiddleware = new AuthMiddleware();

const CustomRoute = ({
  auth,
  component: Component,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Route
      {...rest}
      render={props => {
        const { options } = rest.options || {};

        if (!auth) {
          return (
            <>
              <Header />
              <Component {...props} options={options} />
            </>
          );
        }

        if (!authMiddleware.validate()) {
          return authMiddleware.reject();
        }

        return (
          <div className={classes.root}>
            <CssBaseline />
            <AppBarCustom classes={classes} />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Component {...props} options={options} />
            </main>
          </div>
        );
      }}
    />
  );
};

export default CustomRoute;
