import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  black: '#000000',
  error: 'red',
  dark: '#1C1C1C',
  darkBlue: '#005670',
  gray: '#9A9A9A',
  midGray: '#757575',
  lightGray: '#797979',
  darkGray: '#2a2a2a',
  backGray: '#F8F8F8',
  orangeGrid:
    // eslint-disable-next-line max-len
    'linear-gradient(rgba(255, 163, 0, 0.2) .1em, transparent .1em), linear-gradient(90deg, rgba(255, 163, 0, 0.2) .1em, transparent .1em)',
  orange: '#008945',
  green: '#008945',
  lightGreen: '#84BF48',
  orangeGradient:
    'linear-gradient(180deg, #008945 0%, #008945 40%, #E76967 100%)',
  success: 'limegreen',
  white: '#FFFFFF',
  yellow: '#FFA300',
};

export const breakpoints = {
  MOBILE: 480,
  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.green,
    },
    secondary: {
      main: colors.black,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Inter'",
  },
  overrides: {
    MuiContainer: {
      root: {
        width: '100%',
        display: 'block',
        boxSizing: 'border-box',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '16px',
        paddingRight: '16px',
        '@media (min-width: 600px)': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: `${colors.lightGreen}`,
        boxShadow:
          // eslint-disable-next-line max-len
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: `${colors.white} !important`,
        color: `${colors.green} !important`,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        background: `${colors.white} !important`,
        color: `${colors.green} !important`,
      },
    },
    MuiTextField: {
      root: {
        margin: '0px 0 20px',
        border: `solid 1px ${colors.lightGray}`,
        paddingLeft: '25px',
        height: '52px',
        paddingTop: '10px',
        paddingBottom: '6px',
        background: `${colors.white}`,
      },
    },
    MuiInputLabel: {
      root: {
        marginLeft: '25px',
        fontSize: '16px',
        "&[data-shrink='true']": {
          color: `${colors.dark}`,
          transform: 'translate(0, 10px)',
        },
      },
    },
    MuiLink: {
      root: {
        color: `${colors.green}`,
        fontFamily: "'InterBold'",
      },
    },
    MuiInputBase: {
      input: {
        '&:-internal-autofill-selected': {
          backgroundColor: `${colors.white} !important`,
        },
        '&:-webkit-autofill': {
          transition:
            'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
        },
        '&:-webkit-autofill:focus': {
          transition:
            'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
        },
        '&:-webkit-autofill:hover': {
          transition:
            'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: `${colors.green}`,
        border: 0,
        height: 50,
        boxShadow: 'none',
        color: `${colors.white}`,
        fontFamily: "'InterBold'",
        '&:hover': {
          color: `${colors.green}`,
          backgroundColor: `${colors.white}`,
        },
      },
      containedSecondary: {
        backgroundColor: `${colors.white} !important`,
        color: `${colors.green} !important`,
        '&:hover': {
          backgroundColor: `${colors.white} !important`,
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: 'none',
      },
      groupedContainedHorizontal: {
        color: `${colors.white}`,
        borderRadius: 10,
        backgroundColor: `${colors.green}`,
        fontFamily: "'InterBold'",
        fontSize: '12px',
        height: 40,

        '&:hover': {
          backgroundColor: `${colors.green}`,
        },

        '&:first-child': {
          borderLeft: '1px solid',
        },

        '&:not(:last-child)': {
          borderColor: `${colors.green} !important`,
          borderTop: '1px solid',
          borderBottom: '1px solid',
        },

        '&:last-child': {
          borderColor: `${colors.green} !important`,
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderRight: '1px solid',
        },
      },
    },
    MuiListItem: {
      root: {
        color: `${colors.midGray}`,
      },
    },
  },
});

export default { breakpoints, theme };
