function createData(
  consecutivo,
  sector,
  numero,
  estado,
  id,
) {
  return {
    consecutivo,
    sector,
    numero,
    estado,
    id,
  };
}

export const tableHeaders = [
  {
    name: 'Consecutivo',
  },
  {
    name: 'Sector',
  },
  {
    name: 'Número',
  },
  {
    name: 'Estado',
  },
];

export const dataRows = [
  createData(
    1,
    'Profesional',
    10,
    'Preinscrita',
    2560,
  ),
  createData(
    2,
    'Educativo',
    10,
    'Preinscrita',
    2561,
  ),
  createData(
    3,
    'Empresarial',
    10,
    'Preinscrita',
    2562,
  ),
];
