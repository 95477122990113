const styles = () => ({
  profileButton: {
    color: '#fff',
    backgroundColor: '#008945',
    cursor: 'pointer',
  },
  avatarIcon: {
    width: 70,
    height: 70,
  },
});

export default styles;
