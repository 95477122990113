import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

/* eslint-disable import/prefer-default-export */
export const sendPLNMail = params => {
  /*
  const url = [`/padron/searchbycedula/${cedula}`];

  return axios.get(url.join('&'));
  */
  /*
  const { cantonalEnca, tFrmsCandidatos } = params;

  const papeletaData = {
    cantonalEnca,
    tFrmsCandidatos,
  };
  */
  return axios.post('/mailPLN/sendMail', params);
};
