import React from 'react';
import { Redirect } from 'react-router-dom';
import Storage from '../Storage/Storage';

class AuthMiddleware {
  constructor() {
    this.storage = Storage.instance;
  }

  validate() {
    const token = this.storage.getToken();
    return token !== null && token !== '';
  }

  // eslint-disable-next-line class-methods-use-this
  reject() {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }
}

export default AuthMiddleware;
