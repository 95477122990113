/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Formik, Field, Form } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { PlnInput } from '../Input';
import REGEX from '../../Core/Utils/RegExpUtils';

import style from './styles';

import { updatePersonInfo } from '../../Core/Api/padronApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const ModalHojaInfo = props => {
  const {
    classes,
    onClose,
    // value: valueProp,
    open,
    // currentDelegado,
    currentPerson,
    addPersonToRow,
    setCurrentSexChanged,
  } = props;

  const frmInitValues = {
    sexo: '',
    email: '',
    tel_casa: '',
    tel_celular: '',
    dir_habitacion: '',
    dir_trabajo: '',
    tel_trabajo: '',
    fax_trabajo: '',
  };
  const [iValues2, setIValues2] = useState(frmInitValues);

  const [msjDialog, setMsjDialog] = useState('');
  const [openMsjDialog, setOpenMsjDialog] = useState(false);

  const handleCloseMsjDialog = () => {
    setOpenMsjDialog(false);
  };

  const validationSchema = Yup.object({});
  const initialValues2 = iValues2;

  const changeToNewSex = event => {
    event.persist();
    setCurrentSexChanged(event.target.value);
  };

  useEffect(() => {
    if (open) {
      setIValues2({
        sexo: currentPerson.sexo
          ? `${currentPerson.sexo}`
          : '',
        email: currentPerson.des_email
          ? currentPerson.des_email
          : '',
        tel_casa: currentPerson.tel_casa
          ? currentPerson.tel_casa
          : '',
        tel_celular: currentPerson.tel_celu
          ? currentPerson.tel_celu
          : '',
        dir_habitacion: currentPerson.des_direccion
          ? currentPerson.des_direccion
          : '',
        dir_trabajo: currentPerson.des_direccion_trabajo
          ? currentPerson.des_direccion_trabajo
          : '',
        tel_trabajo: currentPerson.tel_ofi
          ? currentPerson.tel_ofi
          : '',
        fax_trabajo: currentPerson.num_fax_ofi
          ? currentPerson.num_fax_ofi
          : '',
      });
    }
  }, [open, setIValues2]);

  const handleCancel = () => {
    onClose(false);
    setIValues2(!iValues2);
  };

  const addInfoPerson = (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      dir_habitacion,
      dir_trabajo,
      email,
      fax_trabajo,
      sexo,
      tel_casa,
      tel_celular,
      tel_trabajo,
    } = values;

    const personData = {
      num_cedula: currentPerson.num_cedula,
      // eslint-disable-next-line radix
      ind_sexo: parseInt(sexo),
      telefonosData: {
        tel_casa,
        tel_celu: tel_celular,
        tel_ofi: tel_trabajo,
        num_fax_ofi: fax_trabajo,
      },
      direccionesData: {
        des_direccion: dir_habitacion,
        des_direccion_trabajo: dir_trabajo,
      },
      emailsData: {
        des_email: email,
      },
    };

    updatePersonInfo(personData).then(result => {
      const response = Lodash.get(result, [
        'data',
        'response',
      ]);
      if (response > 0) {
        addPersonToRow(parseInt(sexo));
        setSubmitting(false);
        onClose(true);
      } else {
        setSubmitting(false);
        setOpenMsjDialog(true);
        setMsjDialog(
          'Ha Ocurrido un Error! Esta persona NO puede ser agregada, favor intentarlo nuevamente.',
        );
      }
    });
  };

  const formikValidate = values => {
    const errors = {};
    // eslint-disable-next-line no-unused-vars
    const { sexo, email, tel_casa, tel_celular } = values;

    if (!email) {
      errors.email = 'Requerido';
    } else if (!REGEX.email.test(email)) {
      errors.email = 'Email inválido';
    }

    if (!sexo) {
      errors.sexo = 'Requerido';
    }

    /*
    if (!tel_casa) {
      errors.tel_casa = 'Requerido';
    } else if (!REGEX.phone.test(tel_casa)) {
      errors.tel_casa = 'Formato inválido';
    }
    */

    if (!tel_celular) {
      errors.tel_celular = 'Requerido';
    } else if (!REGEX.phone.test(tel_celular)) {
      errors.tel_celular = 'Formato inválido';
    }

    return errors;
  };

  ModalHojaInfo.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // value: PropTypes.string.isRequired,
    // currentDelegado: PropTypes.shape({}).isRequired,
    currentPerson: PropTypes.shape({}).isRequired,
    addPersonToRow: PropTypes.func.isRequired,
    setCurrentSexChanged: PropTypes.func.isRequired,
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Formik
          enableReinitialize
          validate={formikValidate}
          validationSchema={validationSchema}
          initialValues={initialValues2}
          onSubmit={addInfoPerson}
        >
          {formik => (
            <Form>
              <DialogTitle
                id="alert-dialog-slide-title"
                color="warning"
              >
                Actualización de Datos
              </DialogTitle>
              <DialogContent>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                  className={classes.sectionTitle}
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  Hoja de Información
                </Typography>
                <Grid
                  container
                  spacing={3}
                  style={{ padding: 20 }}
                >
                  <Grid item xs={12} sm={4}>
                    <h4 className={classes.fieldTitle}>
                      Cédula
                    </h4>
                    <div className={classes.fieldFilled}>
                      {currentPerson.num_cedula}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <h4 className={classes.fieldTitle}>
                      Nombre
                    </h4>
                    <div className={classes.fieldFilled}>
                      {`${currentPerson.des_nombre} ${currentPerson.des_apell1} ${currentPerson.des_apell2}`}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <h4 className={classes.fieldTitle}>
                      <span style={{ color: '#cc0000' }}>
                        *
                      </span>
                      Sexo
                    </h4>
                    <Field
                      component={RadioGroup}
                      name="sexo"
                      row
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Masculino"
                        disabled={
                          parseInt(formik.values.sexo) > 0
                        }
                        onChange={changeToNewSex}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Femenino"
                        disabled={
                          parseInt(formik.values.sexo) > 0
                        }
                        onChange={changeToNewSex}
                      />
                    </Field>
                    {formik.errors.sexo &&
                    formik.touched.sexo ? (
                      <div className={classes.formError}>
                        <Alert severity="error">
                          {formik.errors.sexo}
                        </Alert>
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <h4 className={classes.fieldTitle}>
                      <span style={{ color: '#cc0000' }}>
                        *
                      </span>
                      Correo Electrónico
                    </h4>
                    <Field
                      name="email"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                    {formik.errors.email &&
                    formik.touched.email ? (
                      <div className={classes.formError}>
                        <Alert severity="error">
                          {formik.errors.email}
                        </Alert>
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <h4 className={classes.fieldTitle}>
                      <span style={{ color: '#cc0000' }}>
                        {' '}
                      </span>
                      Teléfono Habitación
                    </h4>
                    <Field
                      mask="99999999"
                      name="tel_casa"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                    {formik.errors.tel_casa &&
                    formik.touched.tel_casa ? (
                      <div className={classes.formError}>
                        <Alert severity="error">
                          {formik.errors.tel_casa}
                        </Alert>
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <h4 className={classes.fieldTitle}>
                      <span style={{ color: '#cc0000' }}>
                        *
                      </span>{' '}
                      Celular
                    </h4>
                    <Field
                      mask="99999999"
                      name="tel_celular"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                    {formik.errors.tel_celular &&
                    formik.touched.tel_celular ? (
                      <div className={classes.formError}>
                        <Alert severity="error">
                          {formik.errors.tel_celular}
                        </Alert>
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <h4 className={classes.fieldTitle}>
                      Dirección de Habitación
                    </h4>
                    <Field
                      name="dir_habitacion"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <h4 className={classes.fieldTitle}>
                      Dirección de Trabajo
                    </h4>
                    <Field
                      name="dir_trabajo"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h4 className={classes.fieldTitle}>
                      Teléfono Trabajo
                    </h4>
                    <Field
                      name="tel_trabajo"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h4 className={classes.fieldTitle}>
                      Fax Trabajo
                    </h4>
                    <Field
                      name="fax_trabajo"
                      component={PlnInput}
                      type="text"
                      style={{
                        margin: 0,
                        padding: '10px 10px 10px 10px',
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  color="secondary"
                  className={classes.btnActions}
                  disabled={formik.isSubmitting}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={
                    formik.isSubmitting
                      ? classes.btnSubmitting
                      : classes.btnActions
                  }
                  style={{ marginRight: 20 }}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                  ) : (
                    <>Agregar</>
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={openMsjDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseMsjDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-confirm-messages"
          color="warning"
        >
          Mensaje
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {msjDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseMsjDialog}
            color="primary"
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default withStyles(style)(ModalHojaInfo);
