/* eslint-disable radix */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import style from './styles';

import { PlnDropdown } from '../../Components/Input';
import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';
import { getEstadoPapeleta } from '../../Core/Utils/Utils';
import { generateComprobanteSectorial } from '../../Core/Utils/Comprobante';
import { startPrevencion } from '../../Core/Utils/Prevencion';

import Consts from '../../Core/Consts/Consts';
import Storage from '../../Storage/Storage';

import {
  addNew,
  updatePapeleta,
  getNumPapeletasSectoriales,
  getNumPapeletaStatus,
  blockNumPapeleta,
} from '../../Core/Api/papeletaSectorial';

import { useCRUDAvailable } from '../../Core/Api/periodoApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const AddPapeletaSector = props => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );
  const { classes } = props;
  const history = useHistory();

  const [user, setUser] = useState([]);
  const [statusPapeleta] = useState(3);
  const [dateField, setDateField] = useState('');
  const [consecutivo, setConsecutivo] = useState('');
  const [codPapeleta, setCodPapeleta] = useState(null);
  const [fecPapeleta, setFecPapeleta] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showfilters, setShowfilters] = useState(true);
  const [showNumPapeleta, setShowNumPapeleta] = useState(
    false,
  );

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );
  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };

  const closeFinalDialog = () => {
    history.push('/sectoriales');
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }

    const today = new Date();
    setDateField(
      today.toLocaleDateString('es-ES', options),
    );
  }, [decodedToken, options]);

  const [showForm, setShowForm] = useState(false);

  // Opciones Seleccionadas
  const [sSector, setSSector] = useState(9);
  const [sPapeleta, setSPapeleta] = useState(999);
  const [numCandidatos] = useState(3);
  const [
    listComiteSectorial,
    setListComiteSectorial,
  ] = useState(Consts.initListComiteSectorial);

  // Lista de: Provincias, Cantones, Distritos
  const [initialValues] = useState({
    sector: 9,
    papeleta: 999,
    sectorial1: '',
    sectorial2: '',
    sectorial3: '',
  });

  const [sectores] = useState([
    { itemLabel: '- SELECCIONE EL SECTOR -', value: 9 },
    { itemLabel: 'Profesional', value: 2 },
    { itemLabel: 'Educativo', value: 3 },
    { itemLabel: 'Empesarial', value: 4 },
  ]);

  const [papeletas, setPapeletas] = useState([
    { itemLabel: '- NUMERO DE PAPELETA -', value: 999 },
  ]);

  const setSector = c => {
    initialValues.sector = sSector.value;

    if (c !== 9) {
      setShowForm(true);
      setShowNumPapeleta(true);
      setShowfilters(false);
      getNumPapeletasSectoriales(c).then(result => {
        if (result.status === 200) {
          const papeletasList = Lodash.get(result, [
            'data',
          ]);
          const plist = papeletasList.map(({ numero }) => {
            return {
              itemLabel: numero,
              value: numero,
            };
          });
          plist.unshift({
            itemLabel: '- NUMERO DE PAPELETA -',
            value: 999,
          });

          setPapeletas(plist);
        }
      });

      setSSector(
        Lodash.find(sectores, {
          value: c,
        }),
      );
    }
  };

  const setPapeleta = p => {
    initialValues.papeleta = p;
    setSPapeleta(
      Lodash.find(papeletas, {
        value: p,
      }),
    );
  };

  const validationSchema = Yup.object({});

  // Se valida que exista al menos 1 en la lista
  const verifyIfCanSave = lCandidates => {
    const totalRegistersOnList = Object.keys(
      lCandidates,
    ).map(c => {
      const total = Lodash.countBy(lCandidates[c], rec => {
        return rec.filled === true;
      });
      const result = Lodash.get(total, 'true', 0);
      return { count: result };
    });

    const canSave = Lodash.find(totalRegistersOnList, o => {
      return o.count > 0;
    });

    let status = true;
    if (!canSave) {
      status = false;
    }

    return status;
  };

  const saveForm = (
    values,
    { setSubmitting, setFieldValue },
  ) => {
    const { papeleta, sector } = values;

    // Se valida que se haya seleccionado un numero de papeleta
    if (papeleta === 999) {
      setMsjDialog(
        'Debe seleccionar el número de papeleta',
      );
      setOpenDialog(true);
      setIsSaving(false);
      setSubmitting(false);

      return;
    }

    // Se valida que TODOS los campos esten completos al 100%
    const totalComite = Lodash.countBy(
      listComiteSectorial,
      rec => {
        return rec.filled === false;
      },
    );

    if (totalComite.true !== undefined) {
      setMsjDialog(
        'Debe de completarse TODOS los campos de la papeleta al 100%',
      );
      setOpenDialog(true);
      setSubmitting(false);
      return;
    }

    // Segun el estado de la papeleta, se Crea o Actualiza
    getNumPapeletaStatus({
      papeleta,
      sector,
    }).then(result => {
      const papeletaStatus = Lodash.get(
        result,
        ['data'],
        0,
      );
      if (papeletaStatus === 1) {
        // Encabezado Papeleta
        const sectorialEnca = {
          ind_sector: sector,
          cod_estado: 2,
          numero: papeleta,
          num_consecutivo: consecutivo,
          cedula_resp: user.num_cedula,
          usuario: user.des_login,
        };
        // Detalle Papeleta
        const tFrmsCandidatos = {
          comite: listComiteSectorial,
        };

        // Se valida que exista al menos 1 candidato en la papeleta
        const canSave = verifyIfCanSave(tFrmsCandidatos);
        if (canSave) {
          if (consecutivo === '') {
            // Se valida el 20% de la Cuota de Juventud
            const porcentajeDelegados = Math.floor(
              tFrmsCandidatos.comite.length * 0.2,
            );

            const totalCuotaJuventud = Lodash.countBy(
              tFrmsCandidatos.comite,
              rec => {
                return (
                  Number.isInteger(rec.edad) &&
                  rec.edad <= 35
                );
              },
            );

            if (
              totalCuotaJuventud.true === undefined ||
              totalCuotaJuventud.true < porcentajeDelegados
            ) {
              setMsjDialog(
                // eslint-disable-next-line max-len
                'La papeleta NO cumple con el 20% de la cuota de Juventud. Favor revise los miembros de su papeleta',
              );
              setOpenDialog(true);
              setSubmitting(false);
              return;
            }

            // Se Crea Nueva Papeleta
            addNew({
              sectorialEnca,
              tFrmsCandidatos,
            }).then(rNew => {
              const nCodPapeleta = Lodash.get(
                rNew,
                ['data', 'codPapeleta'],
                '',
              );
              const nConsecutivo = Lodash.get(
                rNew,
                ['data', 'consecutivo'],
                '',
              );

              const fechaPapeleta = Lodash.get(
                rNew,
                ['data', 'fecha'],
                '',
              );
              const nMontos = Lodash.get(
                rNew,
                ['data', 'montos'],
                '',
              );

              // Se bloquea el numero de papeleta
              blockNumPapeleta({
                numPapeleta: sPapeleta.value,
                codSector: sSector.value,
              });

              // COMPROBANTE: Se genera el Comprobante de la papeleta

              // Informacion General
              const generalInfo = {
                sector: sSector.itemLabel,
                papeleta: sPapeleta.value,
                fecha: fechaPapeleta,
                consecutivo: nConsecutivo,
              };

              // Informacion Gestor
              const gestorInfo = {
                cedula: user.num_cedula,
                nombre: user.des_nombre,
              };

              // Informacion Comite
              const candidatosComite = tFrmsCandidatos.comite.map(
                ({ cedula, lugar, nombre }) => {
                  return [lugar, cedula, nombre];
                },
              );

              // Informacion Candidatos
              const candidatosInfo = {
                comite: candidatosComite,
              };
              const montoInfo = {
                papeleta: nMontos.papeleta,
                membresia: nMontos.membresia,
                total: nMontos.total,
              };
              generateComprobanteSectorial(
                'ASAMBLEAS DISTRITALES',
                generalInfo,
                candidatosInfo,
                gestorInfo,
                montoInfo,
              );

              // PROCESO DE PREVENCIÓN
              startPrevencion(
                user,
                nCodPapeleta,
                nConsecutivo,
                5,
                'Papeleta Sectorial',
                fechaPapeleta,
                gestorInfo,
              );

              setSubmitting(false);
              setConsecutivo(nConsecutivo);
              setCodPapeleta(nCodPapeleta);
              setMsjDialog(
                'La información de la papeleta se GUARDÓ correctamente.',
              );
              setFinalDialog(true);
            });
          } else {
            // Se valida el 20% de la Cuota de Juventud
            const porcentajeDelegados = Math.floor(
              tFrmsCandidatos.comite.length * 0.2,
            );

            const totalCuotaJuventud = Lodash.countBy(
              tFrmsCandidatos.comite,
              rec => {
                return (
                  Number.isInteger(rec.edad) &&
                  rec.edad <= 35
                );
              },
            );

            if (
              totalCuotaJuventud.true === undefined ||
              totalCuotaJuventud.true < porcentajeDelegados
            ) {
              setMsjDialog(
                // eslint-disable-next-line max-len
                'La papeleta NO cumple con el 20% de la cuota de Juventud. Favor revise los miembros de su papeleta',
              );
              setOpenDialog(true);
              setSubmitting(false);
              return;
            }

            // Se Actualiza la Papeleta
            updatePapeleta({
              codPapeleta,
              sectorialEnca,
              tFrmsCandidatos,
            }).then(rUpdate => {
              const nCodPapeleta = Lodash.get(
                rUpdate,
                ['data', 'codPapeleta'],
                '',
              );

              const nMontos = Lodash.get(
                rUpdate,
                ['data', 'montos'],
                '',
              );

              if (nCodPapeleta !== '') {
                // Se bloquea el numero de papeleta
                blockNumPapeleta({
                  numPapeleta: sPapeleta.value,
                  codSector: sSector.value,
                });

                // COMPROBANTE: Se genera el Comprobante de la papeleta
                // Informacion General
                const generalInfo = {
                  sector: sSector.itemLabel,
                  papeleta: sPapeleta.value,
                  fecha: fecPapeleta,
                  consecutivo,
                };

                // Informacion Gestor
                const gestorInfo = {
                  cedula: user.num_cedula,
                  nombre: user.des_nombre,
                };

                // Informacion Comite
                const candidatosComite = tFrmsCandidatos.comite.map(
                  ({ cedula, lugar, nombre }) => {
                    return [lugar, cedula, nombre];
                  },
                );

                // Informacion Candidatos
                const candidatosInfo = {
                  comite: candidatosComite,
                };
                const montoInfo = {
                  papeleta: nMontos.papeleta,
                  membresia: nMontos.membresia,
                  total: nMontos.total,
                };

                generateComprobanteSectorial(
                  'ASAMBLEAS DISTRITALES',
                  generalInfo,
                  candidatosInfo,
                  gestorInfo,
                  montoInfo,
                );

                setSubmitting(false);
                setMsjDialog(
                  'La Información de la papeleta se ACTUALIZÓ correctamente.',
                );
                setFinalDialog(true);
              } else {
                setMsjDialog(
                  'La información NO pudo ser actualizada',
                );
                setSubmitting(false);
                setOpenDialog(true);
              }
            });
          }
        } else {
          setMsjDialog(
            'Al menos debe de haber ingresado un candidato en la papeleta',
          );
          setOpenDialog(true);
          setSubmitting(false);
        }
      } else {
        setMsjDialog(
          // eslint-disable-next-line max-len
          'ATENCIÓN: El número de papeleta seleccionado YA fue elegido. Debe de seleccionar otro número de papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
        setSubmitting(false);
        setSPapeleta(
          Lodash.find(papeletas, {
            value: 999,
          }),
        );
        setFieldValue('papeleta', 999);
        getNumPapeletasSectoriales(sector).then(r => {
          if (r.status === 200) {
            const papeletasList = Lodash.get(r, ['data']);
            const plist = papeletasList.map(p => {
              return {
                itemLabel: p.papeleta,
                value: p.papeleta,
              };
            });
            plist.unshift({
              itemLabel: '- NUMERO DE PAPELETA -',
              value: 999,
            });

            setPapeletas(plist);
          }
        });
      }
    });
  };

  const savePapeleta = values => () => {
    setIsSaving(true);
    const { papeleta, sector } = values;

    // Se valida que se haya seleccionado un numero de papeleta
    if (papeleta === 999) {
      setMsjDialog(
        'Debe seleccionar el número de papeleta',
      );
      setOpenDialog(true);
      setIsSaving(false);
      return;
    }

    const tFrmsCandidatos = {
      comite: listComiteSectorial,
    };

    // Se valida que exista al menos 1 candidato en la papeleta
    const canSave = verifyIfCanSave(tFrmsCandidatos);
    if (canSave) {
      const sectorialEnca = {
        ind_sector: sector,
        cod_estado: 3,
        numero: papeleta,
        num_consecutivo: consecutivo,
        cedula_resp: user.num_cedula,
        usuario: user.des_login,
      };
      if (consecutivo === '') {
        addNew({ sectorialEnca, tFrmsCandidatos }).then(
          result => {
            const nCodPapeleta = Lodash.get(
              result,
              ['data', 'codPapeleta'],
              '',
            );
            const nConsecutivo = Lodash.get(
              result,
              ['data', 'consecutivo'],
              '',
            );
            const fechaPapeleta = Lodash.get(
              result,
              ['data', 'fecha'],
              '',
            );
            setConsecutivo(nConsecutivo);
            setCodPapeleta(nCodPapeleta);
            setFecPapeleta(fechaPapeleta);
            setMsjDialog(
              'La información de la papeleta se GUARDÓ correctamente.',
            );
            setOpenDialog(true);
          },
        );
      } else {
        updatePapeleta({
          codPapeleta,
          sectorialEnca,
          tFrmsCandidatos,
        }).then(result => {
          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          if (nCodPapeleta !== '') {
            setMsjDialog(
              'La Información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setOpenDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
          }
        });
      }
    } else {
      setMsjDialog(
        'Al menos debe de haber ingresado un candidato en la papeleta',
      );
      setOpenDialog(true);
      setIsSaving(false);
    }
  };

  AddPapeletaSector.propTypes = {};

  return (
    <>
      {crudAvailable ? (
        <Container
          maxWidth="lg"
          className={classes.container}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  Nueva Papeleta de Sector
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.btnBack}
                    onClick={() => {
                      history.push('/sectoriales');
                    }}
                  >
                    <ArrowBackIcon /> Regresar
                  </Button>
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={saveForm}
                >
                  {formik => (
                    <>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid
                          container
                          spacing={3}
                          style={
                            showfilters
                              ? { display: 'flex' }
                              : { display: 'none' }
                          }
                        >
                          <Grid item xs={12} sm={4}>
                            <h4>Sector</h4>
                            <Field
                              name="sector"
                              data={sectores}
                              component={PlnDropdown}
                              onChange={sector =>
                                setSector(sector)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          style={
                            showNumPapeleta
                              ? { display: 'flex' }
                              : { display: 'none' }
                          }
                        >
                          <Grid item xs={12} sm={3}>
                            <h4>Número de Papeleta:</h4>
                            <Field
                              name="papeleta"
                              data={papeletas}
                              component={PlnDropdown}
                              onChange={papeleta =>
                                setPapeleta(papeleta)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Divider />

                        {showForm ? (
                          <div>
                            <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                              gutterBottom
                              className={
                                classes.sectionTitle
                              }
                            >
                              Datos del Gestor
                            </Typography>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4}>
                                <h4
                                  className={
                                    classes.fieldTitle
                                  }
                                >
                                  Estado
                                </h4>
                                <div
                                  className={
                                    classes.fieldFilled
                                  }
                                >
                                  {getEstadoPapeleta(
                                    statusPapeleta,
                                  )}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <h4
                                  className={
                                    classes.fieldTitle
                                  }
                                >
                                  Fecha
                                </h4>
                                <div
                                  className={
                                    classes.fieldFilled
                                  }
                                >
                                  {dateField}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <h4
                                  className={
                                    classes.fieldTitle
                                  }
                                >
                                  Consecutivo
                                </h4>
                                <div
                                  className={
                                    consecutivo === ''
                                      ? classes.field
                                      : classes.fieldFilled
                                  }
                                >
                                  {consecutivo}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <h4
                                  className={
                                    classes.fieldTitle
                                  }
                                >
                                  Cédula
                                </h4>
                                <div
                                  className={
                                    classes.fieldFilled
                                  }
                                >
                                  {user.num_cedula}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <h4
                                  className={
                                    classes.fieldTitle
                                  }
                                >
                                  Nombre
                                </h4>
                                <div
                                  className={
                                    classes.fieldFilled
                                  }
                                >
                                  {user.des_nombre}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <h4
                                  className={
                                    classes.fieldTitle
                                  }
                                >
                                  Sector
                                </h4>
                                <div
                                  className={
                                    classes.fieldFilled
                                  }
                                >
                                  {sSector.itemLabel}
                                </div>
                              </Grid>
                            </Grid>

                            <ListSearch
                              title="Candidatos"
                              formik={formik}
                              initListData={
                                listComiteSectorial
                              }
                              sexOrderM={
                                Consts.comiteCantonalMOrder
                              }
                              sexOrderF={
                                Consts.comiteCantonalFOrder
                              }
                              fieldToShow={numCandidatos}
                              // eslint-disable-next-line radix
                              inPlace={1}
                              setListFunc={
                                setListComiteSectorial
                              }
                              validateBy={[
                                {
                                  dataVal: 'none',
                                  value: 1,
                                },
                              ]}
                            />

                            <Grid
                              container
                              spacing={3}
                              style={{ marginTop: 40 }}
                            >
                              <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className={
                                    formik.isSubmitting ||
                                    isSaving
                                      ? classes.btnSubmitting
                                      : classes.btnActions
                                  }
                                  disabled={
                                    formik.isSubmitting ||
                                    isSaving
                                  }
                                >
                                  {formik.isSubmitting ? (
                                    <CircularProgress
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    />
                                  ) : (
                                    <>
                                      Enviar
                                      <SendIcon
                                        className={
                                          classes.frmIconButton
                                        }
                                      />
                                    </>
                                  )}
                                </Button>
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  className={
                                    formik.isSubmitting ||
                                    isSaving
                                      ? classes.btnSubmitting
                                      : classes.btnActions
                                  }
                                  disabled={
                                    formik.isSubmitting ||
                                    isSaving
                                  }
                                  onClick={savePapeleta(
                                    formik.values,
                                  )}
                                >
                                  {isSaving ? (
                                    <CircularProgress
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    />
                                  ) : (
                                    <>
                                      Guardar
                                      <SaveIcon
                                        className={
                                          classes.frmIconButton
                                        }
                                      />
                                    </>
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          ''
                        )}
                      </form>
                    </>
                  )}
                </Formik>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    id="alert-confirm-messages"
                    color="warning"
                  >
                    Mensaje
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {msjDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="primary"
                    >
                      Entendido
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={finalDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={closeFinalDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    id="alert-finalDialog"
                    color="warning"
                  >
                    Mensaje
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {msjDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={closeFinalDialog}
                      color="primary"
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          className={classes.container}
          style={{
            textAlign: 'center',
          }}
        >
          <Grid
            container
            spacing={3}
            style={{ marginTop: 20 }}
          >
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                backgroundColor: '#FFE599',
                color: '#FF9326',
              }}
            >
              <h4>
                Esta sección se encuentra temporalmente
                deshabilitada. <br />
                Favor comunicarse con Ezequiel Hernández al
                2519-5521 o al correo
                ehernandez@plndigital.com
              </h4>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default withStyles(style)(AddPapeletaSector);
