/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Consts from '../../Core/Consts/Consts';
import Copyright from '../Copyright/Copyright';

import { searchPapeletas } from '../../Core/Api/papeletaPresidenteSector';

function createData(_data) {
  const filterEstado = Consts.estadosDistritales.filter(
    status => {
      return status.codigo === _data.cod_estado;
    },
  );

  return {
    candidato: _data.num_cedula,
    nombre: _data.des_nombre_can,
    estado: filterEstado[0].estado,
    cedula: _data.cedula_resp,
    gestor: _data.des_nombre,
    cod_papeleta: _data.cod_papeleta,
  };
}

const CPPresidenteSector = props => {
  const { classes } = props;

  // eslint-disable-next-line no-unused-vars
  const [showResults, setShowResults] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas

  const [isDataEmpty, setDataEmpty] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]); // used for filtering

  const heads = [
    {
      name: 'Cédula',
    },
    {
      name: 'Candidato(a)',
    },
    {
      name: 'Estado',
    },
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre Gestor(a)',
    },
  ];

  useEffect(() => {
    async function fetchListAPI() {
      await searchPapeletas()
        .then(_response => {
          const info = _response.data.response;

          if (info instanceof Object) {
            const rowItems = info.map(row => {
              return createData(row);
            });

            setFilteredItems(rowItems);
            setDataEmpty(false);
          } else {
            setDataEmpty(true);
          }
        })
        .catch(_error => {
          // eslint-disable-next-line no-console
          console.log(_error);
        });
    }

    fetchListAPI();
  }, []);

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    return actions;
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <h3 style={{ textAlign: 'center' }}>
        CONSULTA PÚBLICA DE INSCRIPCIONES
      </h3>
      <h4
        className={classes.subtitle}
        style={{ textAlign: 'center' }}
      >
        Papeletas Presidente de Sectores
      </h4>

      <h4
        style={{
          textAlign: 'center',
          color: '#008945',
          marginTop: 40,
          fontSize: 18,
        }}
      >
        Candidatos(as) a la Presidencia de Organización
        Sectorial
      </h4>

      <MacroList
        dataEmpty={isDataEmpty}
        listName=""
        uiStylesClasses={classes}
        btnConfig={{
          name: '+ Registro',
          event: () =>
            (window.location.href = `/presidente/add`),
        }}
        actionConfig={setActions}
        tableHeads={heads}
        tableBody={filteredItems}
        displayCopyright={false}
        isCrudAvailable={false}
      />

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

CPPresidenteSector.propTypes = {};

export default withStyles(style)(CPPresidenteSector);
