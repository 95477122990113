import axios from 'axios';
// import Lodash from 'lodash';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// eslint-disable-next-line import/prefer-default-export
export const newDeposito = params => {
  const { depositoData } = params;

  const infoDeposito = {
    deposito: depositoData,
  };

  return axios.post(
    '/depositos/saveDepositoED',
    infoDeposito,
  );
};

export const updateDeposito = deposito => {
  const infoDeposito = {
    deposito,
  };

  return axios.post(
    '/depositos/updateDeposit',
    infoDeposito,
  );
};

export const getAll = cedula => {
  const url = [`/depositos/byGestorED/${cedula}`];

  return axios.get(url.join('&'));
};

export const getDepositInfoByCod = codDeposit => {
  return axios.get(
    `/depositos/getDepositoED/${codDeposit}`,
  );
};

export const deleteDepositByCod = codDeposit => {
  return axios.get(
    `/depositos/removeDeposit/${codDeposit}`,
  );
};
