/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Lodash from 'lodash';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Field } from 'formik';
import { useJwt } from 'react-jwt';
import * as Yup from 'yup';
import Loading from '../../Components/Loading/Loading';
import { PlnDropdown } from '../../Components/Input';
import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';
import Consts from '../../Core/Consts/Consts';
import style from './styles';
import { getEstadoPapeleta } from '../../Core/Utils/Utils';
import { generateComprobanteCantonal } from '../../Core/Utils/Comprobante';
import { startPrevencion } from '../../Core/Utils/Prevencion';
import Storage from '../../Storage/Storage';

import {
  // addNew,
  updatePapeleta,
  usePapeletaInfo,
  getNumPapeletasCantonales,
  getNumPapeletaStatus,
  blockNumPapeleta,
} from '../../Core/Api/papeletaCantonal';

import { useCRUDAvailable } from '../../Core/Api/periodoApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const EditCantonales = props => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );
  const { classes } = props;
  const { id } = useParams();
  const history = useHistory();

  const [user, setUser] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [listComite, setListComite] = useState(
    Consts.initListComiteCantonal,
  );

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );
  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };
  const closeFinalDialog = () => {
    history.push('/cantonales');
  };

  const validationSchema = Yup.object({});

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const numCedula = Lodash.get(
    decodedToken,
    ['num_cedula'],
    0,
  );

  const { data } = usePapeletaInfo({
    cedGestor: numCedula,
    codPapeleta: id,
  });

  // Datos Generales de la papeleta
  const papeletaData = Lodash.get(
    data,
    ['papeletaData'],
    [],
  );

  // Cantidad de Candidatos
  // eslint-disable-next-line no-unused-vars
  const numDelegados = Lodash.get(
    data,
    ['numDelegados'],
    [],
  );

  // Lista de Candidatos incritos
  const candidatos = Lodash.get(data, ['candidatos'], []);

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  if (!Lodash.isEmpty(papeletaData)) {
    const {
      numero,
      cod_estado,
      provincia,
      canton,
      codcant,
      codprov,
      ind_movimiento,
      fec_registro,
      num_consecutivo,
    } = papeletaData;

    const isSexOrderRequired = Lodash.get(
      data,
      ['isSexOrderRequired'],
      [],
    );
    // console.log(isSexOrderRequired);
    const sexFieldAs = Lodash.get(data, ['sexFieldAs'], []);
    const onlyJuventud = Lodash.get(
      data,
      ['onlyJuventud'],
      [],
    );

    const filterMovimiento = Consts.movimientosCantonales.filter(
      status => {
        return status.codigo === ind_movimiento;
      },
    );

    const desMovimiento = filterMovimiento[0].movimiento.toUpperCase();

    // Date Options
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const today = new Date(fec_registro);
    const papeletaDate = today.toLocaleDateString(
      'es-ES',
      options,
    );

    const initialValues = Lodash.get(
      data,
      ['initValues'],
      [],
    );

    const papeletas = Lodash.get(data, ['papeletas'], []);

    const setPapeleta = p => {
      initialValues.papeleta = p;
    };

    // Se valida que exista al menos 1 en la lista
    const verifyIfCanSave = lCandidates => {
      const totalRegistersOnList = Object.keys(
        lCandidates,
      ).map(c => {
        const total = Lodash.countBy(
          lCandidates[c],
          rec => {
            return rec.filled === true;
          },
        );
        const result = Lodash.get(total, 'true', 0);
        return { count: result };
      });

      const canSave = Lodash.find(
        totalRegistersOnList,
        o => {
          return o.count > 0;
        },
      );

      let status = true;
      if (!canSave) {
        status = false;
      }

      return status;
    };

    const saveForm = (
      values,
      { setSubmitting, setFieldValue },
    ) => {
      const { papeleta, movimiento } = values;
      // Se valida que se haya seleccionado un numero de papeleta
      if (papeleta === 999) {
        setMsjDialog(
          'Debe seleccionar el número de papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
        setSubmitting(false);

        return;
      }

      // Se valida que TODOS los campos esten completos al 100%
      const totalComite = Lodash.countBy(
        listComite,
        rec => {
          return rec.filled === false;
        },
      );

      if (totalComite.true !== undefined) {
        setMsjDialog(
          'Debe de completarse TODOS los campos de la papeleta al 100%',
        );
        setOpenDialog(true);
        setSubmitting(false);
        return;
      }

      // Segun el estado de la papeleta, se Crea o Actualiza
      getNumPapeletaStatus({
        papeleta,
        provincia: codprov,
        canton: codcant,
        movimiento,
      }).then(result => {
        const papeletaStatus = Lodash.get(
          result,
          ['data'],
          0,
        );

        if (papeletaStatus === 1) {
          // Encabezado Papeleta
          const cantonalEnca = {
            codprov,
            codcant,
            ind_movimiento,
            cod_estado: 2,
            numero: papeleta,
            num_consecutivo,
            cedula_resp: user.num_cedula,
            usuario: user.des_login,
          };

          // Detalle Papeleta
          const tFrmsCandidatos = {
            comite: listComite,
          };

          if (ind_movimiento !== 2) {
            // Se valida el 20% de la Cuota de Juventud en el COMITE
            const porcentajeComite = Math.floor(
              tFrmsCandidatos.comite.length * 0.2,
            );

            const totalCuotaJuventudC = Lodash.countBy(
              tFrmsCandidatos.comite,
              rec => {
                return (
                  Number.isInteger(rec.edad) &&
                  rec.edad <= 35
                );
              },
            );

            if (
              totalCuotaJuventudC.true === undefined ||
              totalCuotaJuventudC.true < porcentajeComite
            ) {
              setMsjDialog(
                // eslint-disable-next-line max-len
                'La papeleta NO cumple con el 20% de la cuota de Juventud. Favor revise los miembros de su papeleta',
              );
              setOpenDialog(true);
              setSubmitting(false);
              return;
            }
          }

          // Se Actualiza la Papeleta
          updatePapeleta({
            codPapeleta: id,
            cantonalEnca,
            tFrmsCandidatos,
          }).then(rUpdate => {
            const nCodPapeleta = Lodash.get(
              rUpdate,
              ['data', 'codPapeleta'],
              '',
            );

            const nMontos = Lodash.get(
              rUpdate,
              ['data', 'montos'],
              '',
            );

            if (nCodPapeleta !== '') {
              // Se bloquea el numero de papeleta
              blockNumPapeleta({
                numPapeleta: papeleta,
                codProvincia: codprov,
                codCanton: codcant,
                codMovimiento: movimiento,
              });

              // COMPROBANTE: Se genera el Comprobante de la papeleta
              // Informacion General
              const generalInfo = {
                provincia,
                canton,
                movimiento: desMovimiento,
                papeleta,
                fecha: papeletaDate,
                consecutivo: num_consecutivo,
              };

              // Informacion Gestor
              const gestorInfo = {
                cedula: user.num_cedula,
                nombre: user.des_nombre,
              };

              // Informacion Comite
              const candidatosComite = tFrmsCandidatos.comite.map(
                ({ cedula, lugar, nombre }) => {
                  return [lugar, cedula, nombre];
                },
              );

              // Informacion Candidatos
              const candidatosInfo = {
                comite: candidatosComite,
              };
              const montoInfo = {
                papeleta: nMontos.papeleta,
                membresia: nMontos.membresia,
                total: nMontos.total,
              };

              generateComprobanteCantonal(
                'ASAMBLEAS DISTRITALES',
                generalInfo,
                candidatosInfo,
                gestorInfo,
                montoInfo,
              );

              // PROCESO DE PREVENCIÓN
              startPrevencion(
                user,
                nCodPapeleta,
                num_consecutivo,
                3,
                'Papeleta de Cantonales',
                papeletaDate,
                gestorInfo,
              );

              setSubmitting(false);
              setMsjDialog(
                'La Información de la papeleta se ACTUALIZÓ correctamente.',
              );
              setFinalDialog(true);
            } else {
              setMsjDialog(
                'La información NO pudo ser actualizada',
              );
              setSubmitting(false);
              setOpenDialog(true);
            }
          });
        } else {
          setMsjDialog(
            // eslint-disable-next-line max-len
            'ATENCIÓN: El número de papeleta seleccionado YA fue elegido. Debe de seleccionar otro número de papeleta',
          );
          setOpenDialog(true);
          setIsSaving(false);
          setSubmitting(false);
          setFieldValue('papeleta', 999);
          getNumPapeletasCantonales(
            codprov,
            codcant,
            movimiento,
          ).then(r => {
            if (r.status === 200) {
              const papeletasList = Lodash.get(r, ['data']);
              const plist = papeletasList.map(p => {
                return {
                  itemLabel: p.papeleta,
                  value: p.papeleta,
                };
              });
              plist.unshift({
                itemLabel: '- NUMERO DE PAPELETA -',
                value: 999,
              });

              // setPapeletas(plist);
            }
          });
        }
      });
    };

    const savePapeleta = values => () => {
      setIsSaving(true);
      const { papeleta } = values;

      // Se valida que se haya seleccionado un numero de papeleta
      if (papeleta === 999) {
        setMsjDialog(
          'Debe seleccionar el número de papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
        return;
      }

      const tFrmsCandidatos = {
        comite: listComite,
      };

      const canSave = verifyIfCanSave(tFrmsCandidatos);

      if (canSave) {
        const cantonalEnca = {
          codprov,
          codcant,
          ind_movimiento,
          cod_estado: 3,
          numero: papeleta,
          num_consecutivo,
          cedula_resp: user.num_cedula,
          usuario: user.des_login,
        };

        updatePapeleta({
          codPapeleta: id,
          cantonalEnca,
          tFrmsCandidatos,
        }).then(result => {
          const nCodPapeleta = Lodash.get(
            result,
            ['data', 'codPapeleta'],
            '',
          );
          if (nCodPapeleta !== '') {
            setMsjDialog(
              'La Información de la papeleta se ACTUALIZÓ correctamente.',
            );
            setOpenDialog(true);
          } else {
            setMsjDialog(
              'La información NO pudo ser actualizada',
            );
            setOpenDialog(true);
            setIsSaving(false);
          }
        });
      } else {
        setMsjDialog(
          'Al menos debe de haber ingresado un candidato en la papeleta',
        );
        setOpenDialog(true);
        setIsSaving(false);
      }
    };

    return (
      <>
        {crudAvailable ? (
          <Container
            maxWidth="lg"
            className={classes.container}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                  >
                    {`Editar Papeleta Cantonal: ${id}`}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.btnBack}
                      onClick={() => {
                        history.push('/cantonales');
                      }}
                    >
                      <ArrowBackIcon /> Regresar
                    </Button>
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={saveForm}
                  >
                    {formik => (
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={3}>
                            <h4
                              className={classes.fieldTitle}
                            >
                              Número de Papeleta:
                            </h4>
                            {cod_estado === 3 ? (
                              <Field
                                name="papeleta"
                                data={papeletas}
                                component={PlnDropdown}
                                onChange={papeleta =>
                                  setPapeleta(papeleta)
                                }
                              />
                            ) : (
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {numero}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Divider />
                        <div>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={classes.sectionTitle}
                          >
                            Datos del Gestor
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Estado
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {getEstadoPapeleta(
                                  cod_estado,
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Fecha
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {papeletaDate}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Consecutivo
                              </h4>
                              <div
                                className={
                                  num_consecutivo === ''
                                    ? classes.field
                                    : classes.fieldFilled
                                }
                              >
                                {num_consecutivo}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Cédula
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.num_cedula}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Nombre
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {user.des_nombre}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Provincia
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {provincia}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Cantón
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {canton}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Movimiento
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {desMovimiento}
                              </div>
                            </Grid>
                          </Grid>

                          <ListSearch
                            title="Comité Político Cantonal"
                            formik={formik}
                            initListData={
                              candidatos.listComite
                            }
                            sexOrderM={
                              Consts.comiteCantonalMOrder
                            }
                            sexOrderF={
                              Consts.comiteCantonalFOrder
                            }
                            fieldToShow={0}
                            // eslint-disable-next-line radix
                            inPlace={parseInt(codcant)}
                            setListFunc={setListComite}
                            validateBy={[
                              {
                                dataVal: 'provincia',
                                value: codprov,
                              },
                              {
                                dataVal: 'canton',
                                value: codcant,
                              },
                            ]}
                            requireSexOrder={
                              isSexOrderRequired
                            }
                            sexFieldAs={sexFieldAs}
                            onlyJuventud={onlyJuventud}
                          />

                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: 40 }}
                          >
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                              >
                                {formik.isSubmitting ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Enviar
                                    <SendIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>

                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={
                                  formik.isSubmitting ||
                                  isSaving
                                }
                                onClick={savePapeleta(
                                  formik.values,
                                )}
                              >
                                {isSaving ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Actualizar{' '}
                                    <SaveIcon
                                      style={{
                                        marginLeft: 10,
                                      }}
                                    />
                                  </>
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle
                      id="alert-confirm-messages"
                      color="warning"
                    >
                      Mensaje
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {msjDialog}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color="primary"
                      >
                        Entendido
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={finalDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeFinalDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle
                      id="alert-finalDialog"
                      color="warning"
                    >
                      Mensaje
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {msjDialog}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={closeFinalDialog}
                        color="primary"
                      >
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        ) : (
          <Container
            maxWidth="lg"
            className={classes.container}
            style={{
              textAlign: 'center',
            }}
          >
            <Grid
              container
              spacing={3}
              style={{ marginTop: 20 }}
            >
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#FFE599',
                  color: '#FF9326',
                }}
              >
                <h4>
                  Esta sección se encuentra temporalmente
                  deshabilitada. <br />
                  Favor comunicarse con Ezequiel Hernández
                  al 2519-5521 o al correo
                  ehernandez@plndigital.com
                </h4>
              </Grid>
            </Grid>
          </Container>
        )}
      </>
    );
    // eslint-disable-next-line no-else-return
  } else {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Loading label="Cargando" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default withStyles(style)(EditCantonales);
