import React from 'react';
import Typography from '@material-ui/core/Typography';

const Copyright = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
    >
      Copyright ©2021 Partido Liberación Nacional
      <br />
      TELEFONOS: 2549-5500 / 2549-5504 CENTRAL TELEFÓNICA
      {` ${new Date().getFullYear()}`}
      {'.'}
    </Typography>
  );
};

export default Copyright;
