/* eslint-disable no-unused-vars */
import axios from 'axios';
import Lodash from 'lodash';
import { useQuery } from 'react-query';
import Consts from '../Consts/Consts';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const addNew = params => {
  const { provincialEnca, tFrmsCandidatos } = params;

  const papeletaData = {
    provincialEnca,
    tFrmsCandidatos,
  };

  return axios.post('/papeletaProvincial', papeletaData);
};

export const getNumPapeletasProvinciales = (
  idProvince,
  idMovimiento,
) => {
  const url = [
    `/papeletaProvincial/numPapeletas/${idProvince}/${idMovimiento}`,
  ];

  return axios.get(url.join('&'));
};

export const searchPapeletas = (
  codProvincia,
  codMovimiento,
  numero,
) => {
  const url = [
    `/papeletaProvincial/doSearch/${codProvincia}/${codMovimiento}/${numero}`,
  ];

  return axios.get(url.join('&'));
};

export const getDetallePapeleta = codPapeleta => {
  const url = [
    `/papeletaProvincial/getDetallePapeleta/${codPapeleta}`,
  ];

  return axios.get(url.join('&'));
};

export const getNumPapeletaStatus = params => {
  const { papeleta, provincia, movimiento } = params;
  const url = [
    `/papeletaProvincial/numPapeletaStatus/${papeleta}/${provincia}/${movimiento}`,
  ];
  return axios.get(url.join('&'));
};

export const blockNumPapeleta = params => {
  const {
    numPapeleta,
    codProvincia,
    codMovimiento,
  } = params;

  const papeletaData = {
    numPapeleta,
    codProvincia,
    codMovimiento,
  };

  return axios.post(
    '/papeletaProvincial/blockNumPapeleta',
    papeletaData,
  );
};

export const updatePapeleta = params => {
  const {
    codPapeleta,
    provincialEnca,
    tFrmsCandidatos,
  } = params;

  const papeletaData = {
    codPapeleta,
    provincialEnca,
    tFrmsCandidatos,
  };

  return axios.post(
    '/papeletaProvincial/update',
    papeletaData,
  );
};

const getCandidatosPapeleta = async codPapeleta => {
  const url = [
    `/papeletaProvincial/getCandidatosPapeleta/${codPapeleta}`,
  ];

  const { data } = await axios.get(url.join('&'));

  return data;
};

const searchByCedula = async cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];
  const { data } = await axios.get(url.join('&'));
  return data;
};

const preparedList = list => {
  const unlockedList = list.map(e => {
    Lodash.set(e, 'enabled', false);
    return e;
  });
  return unlockedList;
};

const getAllDataPapeleta = async (data, codPapeleta) => {
  const initValues = Consts.provincialesInitValues;
  Lodash.set(initValues, 'provincia', data.cod_provincia);
  Lodash.set(initValues, 'movimiento', data.ind_movimiento);

  // Estado: REGISTRADA
  if (data.cod_estado === 2) {
    Lodash.set(initValues, 'papeleta', 999);
    // Estado: INSCRITA
  } else {
    const numStatus = await getNumPapeletaStatus({
      papeleta: data.numero,
      provincia: data.cod_provincia,
      movimiento: data.ind_movimiento,
    });

    const numeroStatus = Lodash.get(numStatus, ['data']);

    if (numeroStatus === 1) {
      Lodash.set(initValues, 'papeleta', data.numero);
    } else {
      Lodash.set(initValues, 'papeleta', 999);
    }
  }

  const allPapeletas = await getNumPapeletasProvinciales(
    data.cod_provincia,
    data.ind_movimiento,
  );

  const papeletasList = Lodash.get(allPapeletas, ['data']);
  const plist = papeletasList.map(({ numero }) => {
    return {
      itemLabel: numero,
      value: numero,
    };
  });
  plist.unshift({
    itemLabel: '- NUMERO DE PAPELETA -',
    value: 999,
  });
  // console.log(Consts.initlistComiteProvincial);

  // Se preparan las listas para que los campos esten desbloquedos
  const iListComite = preparedList(
    Consts.initlistComiteProvincial,
  );

  // Se obtiene el numero de delegados
  const numCandidatos = 4;

  // Se obtienen todos los candidatos registrados en la papeleta
  const candidatos = await getCandidatosPapeleta(
    codPapeleta,
  );

  let sexFieldAs = '';
  let isSexOrderRequired = true;
  let onlyJuventud = false;

  if (data.ind_movimiento === 1) {
    sexFieldAs = 'F';
    isSexOrderRequired = false;
  }

  if (data.ind_movimiento === 2) {
    onlyJuventud = true;
  }

  /* ====== Lista de Comité ====== */
  const listComite = await Promise.all(
    candidatos.map(async (d, index) => {
      const miembro = await searchByCedula(d.NUM_CEDULA);

      // Datos de la persona
      const person = Lodash.get(miembro, ['response', 0]);

      // Nombre de la persona
      const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
      const personCedula = person.num_cedula;
      const personEdad = person.num_edad;
      let sexVal = 'M';
      if (person.sexo !== 1) {
        sexVal = 'F';
      }

      // Nombre del campo
      const field = Lodash.find(iListComite, {
        numPuesto: d.NUM_PUESTO,
      });

      Lodash.set(initValues, field.fieldName, personCedula);

      return {
        id: field.id,
        lugar: field.lugar,
        numPuesto: d.NUM_PUESTO,
        fieldName: field.fieldName,
        cedula: personCedula,
        nombre: personName,
        sexo: sexVal,
        edad: personEdad,
        enabled: true,
        filled: true,
      };
    }),
  );

  const aComite = iListComite.map(com => {
    const f = Lodash.find(listComite, {
      fieldName: com.fieldName,
    });

    if (f === undefined) {
      return com;
    }
    return f;
  });

  // Merging List Comite
  const allComite = Lodash.merge(iListComite, aComite);

  const firstComite = allComite[0].sexo;
  let sexOrderComite = Consts.comiteProvincialMOrder;
  if (firstComite !== 'M') {
    sexOrderComite = Consts.comiteProvincialFOrder;
  }

  // Se establece el sexo para el resto de la lista
  const finalListComite = Lodash.map(
    allComite,
    (d, index) => {
      const newData = {
        id: d.id,
        lugar: d.lugar,
        numPuesto: d.numPuesto,
        fieldName: d.fieldName,
        cedula: d.cedula,
        nombre: d.nombre,
        sexo: isSexOrderRequired
          ? sexOrderComite[index].sexo
          : sexFieldAs,
        edad: d.edad,
        enabled: d.cedula !== '',
        filled: d.filled,
      };

      return newData;
    },
  );

  return {
    papeletaData: data,
    numCandidatos,
    papeletas: plist,
    candidatos: {
      listComite: finalListComite,
    },
    initValues,
    onlyJuventud,
    sexFieldAs,
    isSexOrderRequired,
  };
};

export const getPapeletaInfo = async params => {
  try {
    const { cedGestor, codPapeleta } = params;

    const url = [
      `/papeletaProvincial/getByCodPapeleta/${cedGestor}/${codPapeleta}`,
    ];

    const { data } = await axios.get(url.join('&'));
    const papeletaData = getAllDataPapeleta(
      data.response[0],
      codPapeleta,
    );

    return papeletaData;
  } catch (e) {
    return { papeletaData: {} };
  }
};

export const usePapeletaInfo = (cedGestor, codPapeleta) => {
  return useQuery(
    ['papeletaData', cedGestor, codPapeleta],
    () => getPapeletaInfo(cedGestor, codPapeleta),
  );
};
