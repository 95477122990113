import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getJRVByDistrit = (_ced, _cant, _codDistrital) => {
  return axiosInstance.get(
    `miembrosMesa/getJuntas/${_ced}/${_cant}/${_codDistrital}`,
  );
};

const saveMiembroMesa = _info => {
  return axiosInstance.post('miembrosMesa/', _info);
};

const getMiembroMesaByNumLinea = _linea => {
  return axiosInstance.get(`miembrosMesa/find/${_linea}`);
};

const getMiembrosMesa = _ced => {
  return axiosInstance.get(
    `miembrosMesa/allByGestor/${_ced}`,
  );
};

const getMiembrosMesaFiltered = (
  _ced,
  _codProvincia,
  _codCanton,
  _codDistrito,
  _nJrv,
) => {
  return axiosInstance.get(
    // eslint-disable-next-line max-len
    `miembrosMesa/allByGestorFiltered/${_ced}/${_codProvincia}/${_codCanton}/${_codDistrito}/${_nJrv}`,
  );
};

const validateMemberOnAdd = _ced => {
  return axiosInstance.get(
    `miembrosMesa/validateCedula/${_ced}`,
  );
};

const validateIfExist = (jrv, puesto, cedula) => {
  return axiosInstance.get(
    `miembrosMesa/validarExistencia/${jrv}/${puesto}/${cedula}`,
  );
};

const deleteMember = _numLinea => {
  return axiosInstance.get(
    `miembrosMesa/removeMember/${_numLinea}`,
  );
};

export default {
  getJuntaReceptora: getJRVByDistrit,
  getAllMiembrosMesa: getMiembrosMesa,
  getAllMiembrosMesaFiltered: getMiembrosMesaFiltered,
  getMiembroInfoByLinea: getMiembroMesaByNumLinea,
  submitMiembroMesa: saveMiembroMesa,
  validateMemberOnAdd,
  validateIfExist,
  deleteMember,
};
