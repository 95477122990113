import { colors } from '../../Core/Theme/theme';

const styles = theme => ({
  container: {
    textAlign: 'center',
    color: colors.lightGray,
    '& h3': {
      color: colors.green,
      marginTop: '50px',
      fontSize: '24px',
      marginBottom: '10px',
    },
    '& h4': {
      textWeight: 'bold',
      marginTop: '0px',
      marginBottom: '5px',
    },
    '& p': {
      marginTop: '0px',
      marginBottom: '5px',
    },
  },
  boxForm: {
    marginTop: '30px',
    marginBottom: '15px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  fieldFilled: {
    border: 'solid thin #ccc',
    padding: 10,
    background: '#f1f1f1',
  },
});

export default styles;
