function createData(
  consecutivo,
  movimiento,
  puesto,
  cedula,
  primerApellido,
  segundoApellido,
  nombre,
  estado,
  id,
) {
  return {
    consecutivo,
    movimiento,
    puesto,
    cedula,
    primerApellido,
    segundoApellido,
    nombre,
    estado,
    id,
  };
}

export const tableHeaders = [
  {
    name: 'Consecutivo',
  },
  {
    name: 'Movimiento',
  },
  {
    name: 'Puesto',
  },
  {
    name: 'Cédula Propietario',
  },
  {
    name: '1er. Apellido',
  },
  {
    name: '2do. Apellido',
  },
  {
    name: 'Nombre',
  },
  {
    name: 'Estado',
  },
];

export const dataRows = [
  createData(
    1,
    'Mujeres',
    'Vicepresidente',
    '100230628',
    'Calvo',
    'Solorzano',
    'Marta',
    'Preinscrita',
    1,
  ),
  createData(
    2,
    'Trabajadores',
    'Vicepresidente',
    '100339724',
    'Delgado',
    'Corrales',
    'Jose',
    'Preinscrita',
    2,
  ),
  createData(
    3,
    'Juventud',
    'Vicepresidente',
    '100351840',
    'Quiros',
    'Quiros',
    'Ricardo',
    'Preinscrita',
    3,
  ),
  createData(
    4,
    'Cooperativo',
    'Vicepresidente',
    '10040188',
    'Mora',
    'Berrocal',
    'Antonio',
    'Anulada',
    4,
  ),
];
