const styles = () => ({
  pageBox: {
    root: { backgroundColor: '#fff' },
    backgroundColor: '#fff',
    padding: 0,
    maxWidth: 1200,
  },
});

export default styles;
