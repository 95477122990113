/* eslint-disable class-methods-use-this */
const StorageSymbol = Symbol('empty');
const StorageSymbolEnforce = Symbol('empty');

class Storage {
  constructor(enforcer) {
    if (enforcer !== StorageSymbolEnforce) {
      throw new Error('Cannot construct singleton');
    }

    // this.isTokenExpired = true;
  }

  static get instance() {
    if (!this[StorageSymbol]) {
      this[StorageSymbol] = new Storage(
        StorageSymbolEnforce,
      );
    }
    return this[StorageSymbol];
  }

  setToken(_token) {
    localStorage.setItem('token', _token);
  }

  getToken() {
    const localToken = localStorage.getItem('token');
    return localToken;
  }

  setIsTokenExpired(_flag) {
    this.isTokenExpired = _flag;
  }

  getIsTokenExpired() {
    return this.isTokenExpired;
  }

  removeStorage() {
    localStorage.removeItem('token');
  }
}

export default Storage;
