import React from 'react';
import Slide from '@material-ui/core/Slide';
import { Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const useStyles = makeStyles(() => ({
  global: {
    padding: '12px 24px',
  },
  divider: {
    backgroundColor: '#008945',
    margin: '0 auto',
    padding: 1,
    width: '96%',
  },
  removeBtn: {
    border: '1px solid',
    borderColor: '#CC0B00',
    color: '#CC0B00',
    '&:hover, &:focus': {
      backgroundColor: '#DA2229 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
  },
  submitBtn: {},
  simpleBtn: {
    border: '1px solid',
    borderColor: '#028945',
    color: '#028945',
    '&:hover, &:focus': {
      backgroundColor: '#028945 !important',
      color: '#fff !important',
    },
  },
}));

const PLNDialog = props => {
  const classes = useStyles();
  const { bodymessage, title, buttons } = props;

  const colors = {
    submit: classes.submitBtn,
    remove: classes.removeBtn,
    simple: classes.simpleBtn,
  };

  const renderButtons = () => {
    if (buttons.length > 0) {
      return buttons.map(button => {
        return (
          <Button
            key={`dialog-action-${button.actionName}`}
            className={colors[button.color]}
            disabled={button.disabled || false}
            onClick={_e => {
              _e.preventDefault();

              if (
                typeof button.clickHander === 'function'
              ) {
                button.clickHander();
              } else {
                throw new Error('Click event is missing!');
              }
            }}
          >
            {button.title}
          </Button>
        );
      });
    }

    return <></>;
  };

  return (
    <Dialog
      {...props}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-confirm-messages"
        color="warning"
        className={classes.global}
      >
        {title}
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {bodymessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.global}>
        {renderButtons()}
      </DialogActions>
    </Dialog>
  );
};

PLNDialog.propTypes = {
  bodymessage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.instanceOf(Array).isRequired,
};

export default PLNDialog;
