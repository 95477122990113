import { colors } from '../../Core/Theme/theme';

const styles = theme => ({
  container: {
    textAlign: 'left',
    color: colors.lightGray,
    '& h3': {
      color: colors.darkGray,
      marginTop: '50px',
      fontSize: '24px',
      marginBottom: '10px',
    },
    '& h4': {
      textWeight: 'bold',
      marginTop: '0px',
      marginBottom: '5px',
    },
    '& p': {
      marginTop: '0px',
      marginBottom: '5px',
    },
  },
  subtitle: { color: colors.green },
  boxForm: {
    marginTop: '30px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  formError: {
    margin: '5px 0px 25px 0px',
    color: `#FF1101`,
  },
});

export default styles;
