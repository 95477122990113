import React, { useState } from 'react';
import { BalanceProvider } from './AppContext';
import balanceGlobalApi from '../Api/balanceGlobalApi';
import { formatData } from '../Utils/Utils';

const AppProvider = props => {
  const { children } = props;

  const [globalBalance, setGlobalBalance] = useState(null);

  const getBalance = async _userId => {
    await balanceGlobalApi
      .getBalanceById(_userId)
      .then(_response => {
        const info = _response;
        let dataSet = {
          deposits: 0,
          memberships: 0,
          inscriptions: 0,
          saldo: 0,
        };

        if (info.status === 200 && info.data.response) {
          const balanceRs = info.data.response;

          dataSet = {
            deposits: formatData(balanceRs.depositos),
            memberships: formatData(balanceRs.membresias),
            inscriptions: formatData(
              balanceRs.inscripciones,
            ),
            saldo: formatData(balanceRs.saldo),
          };
        }

        setGlobalBalance(dataSet);
      });
  };

  return (
    <BalanceProvider
      value={{
        balance: globalBalance,
        getBalanceAPI: getBalance,
        setGlobalBalance,
      }}
    >
      {children}
    </BalanceProvider>
  );
};

export default AppProvider;
