import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import style from './styles';

import tokenStorage from '../../Storage/Storage';
import pageStorage from '../../Storage/ConfigPage';

const LogOut = props => {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [user, setUser] = useState([]);

  const tokenData = tokenStorage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setIsLogout(true);
    const tokenInfo = tokenStorage.instance;
    const pageInfo = pageStorage.instance;

    tokenInfo.removeStorage();
    pageInfo.removeStorage();

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  return (
    <div>
      <Avatar
        className={classes.profileButton}
        onClick={handleClickMenu}
      >
        <PersonIcon />
      </Avatar>
      <Menu
        id="simple-menu"
        style={{ marginTop: 25 }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Card style={{ textAlign: 'center' }}>
            <CardContent>
              <Avatar
                className={classes.avatarIcon}
                style={{ margin: 'auto' }}
              >
                <PersonIcon
                  className={classes.avatarIcon}
                />
              </Avatar>
              <Typography
                className={classes.pos}
                color="textSecondary"
              >
                {Lodash.startCase(
                  Lodash.camelCase(user.des_nombre),
                )}
              </Typography>
              <Typography variant="body2" component="p">
                Gestor
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  margin: 'auto',
                  padding: 5,
                  height: 30,
                }}
              >
                Perfil de Usuario
              </Button>
            </CardActions>
          </Card>
        </MenuItem>
        <MenuItem onClick={handleClickOpen}>
          <ExitToAppIcon />
          Cerrar Sesión
        </MenuItem>
      </Menu>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Desea salir del sistema?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={logout}
            disabled={isLogout}
            color="primary"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(style)(LogOut);
