import React from 'react';
import Lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AdjustIcon from '@material-ui/icons/Adjust';
import MoneyIcon from '@material-ui/icons/Money';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { useRGESMMAvailable } from '../../Core/Api/periodoApi';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    '& a': {
      textDecoration: 'none',
      color: '#757575',
      width: '100%',
    },
  },
}));

export default function MenuItems() {
  // Se valida si esta habilitada la opcion (Registro Gestores)
  const isRegistroMMAvailable = useRGESMMAvailable();
  const registroMMAvailable = Lodash.get(
    isRegistroMMAvailable,
    ['data', 'response'],
    null,
  );
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openElectorales, setElectorales] = React.useState(
    false,
  );
  const [openGafetes, setGafetes] = React.useState(false);
  const [openFormularios, setFormularios] = React.useState(
    false,
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const gafetesClickHandler = () => {
    setGafetes(!openGafetes);
  };

  const electoralesClickHandler = () => {
    setElectorales(!openElectorales);
  };

  const formulariosClickHandler = () => {
    setFormularios(!openFormularios);
  };

  return (
    <div>
      <ListItem button component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Inicio" />
      </ListItem>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Registro Papeletas" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/distritales"
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Distritales" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/cantonales"
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Mov. Cantonales" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/provinciales"
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Mov. Provinciales" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/nacionales"
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Mov. Nacionales" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/presi-sectores"
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Presidencia de Sectores" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/sectoriales"
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Papeletas de Sectores" />
          </ListItem>
        </List>
      </Collapse>
      {registroMMAvailable && (
        <ListItem button onClick={electoralesClickHandler}>
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Registros Electorales" />
          {openElectorales ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItem>
      )}
      <Collapse
        in={openElectorales}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/miembros-mesa"
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText primary="Miembros de Mesa" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/fiscales-mesa"
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText primary="Fiscales de Mesa" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/fiscales-generales"
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText primary="Fiscales Generales" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={gafetesClickHandler}>
        <ListItemIcon>
          <ArtTrackIcon />
        </ListItemIcon>
        <ListItemText primary="Gafetes Electorales" />
        {openGafetes ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse
        in={openGafetes}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/gafetes-miembros-mesa"
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText primary="Miembros de Mesa" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/gafetes-fiscales-mesa"
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText primary="Fiscales de Mesa" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/gafetes-fiscales-generales"
          >
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <ListItemText primary="Fiscales Generales" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={formulariosClickHandler}>
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Formularios" />
        {openFormularios ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={openFormularios}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/papeletas-distritales">
              <ListItemText primary="Mov. Distritales" />
            </a>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/papeletas-movimientos-cantonales">
              <ListItemText primary="Mov. Cantonales" />
            </a>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/papeletas-movimientos-provinciales">
              <ListItemText primary="Mov. Provinciales" />
            </a>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/papeletas-movimientos-nacionales">
              <ListItemText primary="Mov. Nacionales" />
            </a>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/presidente-sectores">
              <ListItemText primary="Presidente de Sectores" />
            </a>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/papeletas-sectores">
              <ListItemText primary="Papeleta de Sectores" />
            </a>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <AdjustIcon />
            </ListItemIcon>
            <a href="/formularioAuth/hoja-informacion">
              <ListItemText primary="Hoja de Información" />
            </a>
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        component={Link}
        to="/registro-depositos"
      >
        <ListItemIcon>
          <MoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Registro de Depósitos" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/consulta-debitos"
      >
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>
        <ListItemText primary="Consulta de Débitos" />
      </ListItem>
    </div>
  );
}
