import React, { useState, useEffect } from 'react';
import { useJwt } from 'react-jwt';
import { makeStyles } from '@material-ui/core/styles';
import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';
import Storage from '../../Storage/Storage';
import { getAll } from '../../Core/Api/debitosApi';
import { formatData } from '../../Core/Utils/Utils';

function createData(_data) {
  return {
    tipo:
      _data.IND_PAPELETA === 0
        ? 'Membresía'
        : 'Inscripción',
    consecutivo: _data.CON_PAPELETA,
    cedula:
      _data.CED_CANDIDATO === null
        ? _data.CED_GESTOR
        : _data.CED_CANDIDATO,
    candidato:
      _data.CED_CANDIDATO === null
        ? _data.nom_gestor
        : _data.nom_candidato,
    monto: `${formatData(_data.NUM_MONTO)}`,
    fechaRegistro: _data.FEC_REGISTRO,
    actions: '',
  };
}

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const ConsultaDebitos = () => {
  const classes = useStyles();
  const tokenStorage = Storage.instance;

  const [items, setItems] = useState([]);
  const [isDataEmpty, setDataEmpty] = useState(false);

  const { decodedToken } = useJwt(tokenStorage.getToken());

  useEffect(() => {
    async function fetchListAPI() {
      if (decodedToken) {
        await getAll(decodedToken.num_cedula)
          .then(_response => {
            const info = _response.data.response;

            if (info instanceof Object) {
              const rowItems = info.map(row => {
                return createData(row);
              });

              setItems(rowItems);
              // setFilteredItems(rowItems);
              setDataEmpty(false);
            } else {
              setDataEmpty(true);
            }
          })
          .catch(_error => {
            // eslint-disable-next-line no-console
            console.log(_error);
          });
      }
    }

    fetchListAPI();
  }, [decodedToken]);

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];
    return actions;
  };

  return (
    <>
      <MacroList
        dataEmpty={isDataEmpty}
        listName="Consulta de Débitos"
        uiStylesClasses={classes}
        btnConfig={{
          name: 'Imprimir',
          event: () => {
            window.print();
          },
        }}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={items}
      />
    </>
  );
};

export default ConsultaDebitos;
