function createData(
  id,
  Provincia,
  movimiento,
  numero,
  estado,
  itemId,
) {
  return {
    id,
    Provincia,
    movimiento,
    numero,
    estado,
    itemId
  };
}

export const tableHeaders = [
  {
    name: 'Consecutivo',
  },
  {
    name: 'Provincia',
  },
  {
    name: 'Movimiento',
  },
  {
    name: 'Número',
  },
  {
    name: 'Estado',
  },
];

export const dataRows = [
  createData(
    1,
    'Heredia',
    'Mujeres',
    8,
    'Preinscrita',
    1,
  ),
  createData(
    2,
    'Heredia',
    'Trabajadores',
    8,
    'Preinscrita',
    2,
  ),
  createData(
    3,
    'Heredia',
    'Juventud',
    8,
    'Preinscrita',
    3,
  ),
  createData(
    4,
    'Heredia',
    'Cooperativo',
    8,
    'Anulada',
    4
  ),
];
