import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import style from './styles';

const PlnInput = withStyles(style)(props => {
  const {
    classes,
    label,
    type,
    disabled,
    field,
    form: { touched, errors },
    required: isRequired,
    mask,
    placeholder,
    style,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <label
        htmlFor={field.name}
        className={clsx(
          classes.inputLabel,
          isRequired && classes.required,
        )}
      >
        {t(label)}
      </label>
      {props.multiLine ? (
        <textarea
          className={clsx(
            classes.input,
            classes.inputMultiline,
            touched[field.name] &&
              errors[field.name] &&
              classes.inputError,
          )}
          {...field}
        />
      ) : (
        <InputMask
          className={clsx(
            classes.input,
            touched[field.name] &&
              errors[field.name] &&
              classes.inputError,
            type === 'password' && classes.password,
          )}
          placeholder={placeholder}
          disabled={disabled}
          maskChar={null}
          mask={mask}
          type={type}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          style={style}
        />
      )}
    </>
  );
});

PlnInput.defaultProps = {
  label: '',
  type: 'text',
  disabled: false,
};

PlnInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  multiLine: (props, propName) => {
    return (
      props[propName] &&
      props.mask &&
      new Error(
        'The multiLine prop and the mask prop cannot both be set',
      )
    );
  },
  mask: (props, propName) => {
    return (
      props[propName] &&
      props.multiLine &&
      new Error(
        'The multiLine prop and the mask prop cannot both be set',
      )
    );
  },
};

export default PlnInput;
