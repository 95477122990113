import { colors } from '../../Core/Theme/theme';

const styles = theme => ({
  container: {
    textAlign: 'left',
    color: colors.lightGray,
    paddingBottom: 80,
    '& h3': {
      color: colors.darkGray,
      marginTop: '50px',
      fontSize: '24px',
      marginBottom: '10px',
    },
    '& h4': {
      textWeight: 'bold',
      marginTop: '0px',
      marginBottom: '5px',
    },
    '& p': {
      marginTop: '0px',
      marginBottom: '5px',
    },
  },
  subtitle: { color: colors.green },
  subtitleDoc: { color: colors.darkGray },
  boxForm: {
    marginTop: '30px',
  },
  boxDocToPdf: {
    color: colors.darkGray,
    '& h3': {
      color: colors.green,
      marginTop: '50px',
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  textFilled: {
    background: '#f1f1f1',
    padding: '10px',
    width: '80%',
    margin: '0 auto',
  },
  customField: {
    background: '#fff',
    border: 'solid thin #999',
    padding: '10px',
    width: '250px',
    margin: '0 auto',
  },
});

export default styles;
