import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  type: {
    padding: '5px 0',
  },
});

const Loading = props => {
  const { label } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress />
      <Typography
        component="h4"
        color="primary"
        gutterBottom
        className={classes.type}
      >
        {label}
      </Typography>
    </div>
  );
};

Loading.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Loading;
