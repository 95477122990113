import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';

const Home = props => {
  const { classes } = props;
  return <div className={classes.container}>Home Page</div>;
};

export default withStyles(style)(Home);
