/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  PlnDropdown,
  PlnInput,
} from '../../Components/Input';
import MacroList from '../MacroList/MacroList';
import style from './styles';
import Consts from '../../Core/Consts/Consts';
import Copyright from '../Copyright/Copyright';

import {
  useProvincias,
  useMovimientos,
} from '../../Core/Api/locationApi';

import {
  searchPapeletas,
  getDetallePapeleta,
} from '../../Core/Api/papeletaProvincial';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

function createData(_data) {
  const filterEstado = Consts.estadosDistritales.filter(
    status => {
      return status.codigo === _data.cod_estado;
    },
  );

  return {
    province: _data.des_nombre_p,
    movimiento: _data.des,
    numero: _data.numero,
    cod_estado: filterEstado[0].estado,
    cedula: _data.cedula_resp,
    gestor: _data.des_nombre,
    cod_papeleta: _data.cod_papeleta,
  };
}

const CPPapeletasProvinciales = props => {
  const { classes } = props;
  const { data } = useProvincias();
  const listMovimientos = useMovimientos();

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState([
    { itemLabel: '- SELECCIONE LA PROVINCIA -', value: 9 },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [sMovimiento, setSMovimiento] = useState(9);

  // INFORMACION DEL DETALLE DE LA PAPELETA
  const [rowSelected, setRowSelected] = useState({
    provincia: '',
    movimiento: '',
    numero: '',
  });

  const [comite, setComite] = useState([]);

  const [isDataEmpty, setDataEmpty] = useState(false);
  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', ''],
  ]); // used for filtering

  const [openPapeleta, setOpenPapeleta] = useState(false);
  const handleClose = () => {
    setOpenPapeleta(false);
  };

  const heads = [
    {
      name: 'Provincia',
    },
    {
      name: 'Movimiento',
    },
    {
      name: 'Número',
    },
    {
      name: 'Estado',
    },
    {
      name: 'Cédula',
    },
    {
      name: 'Nombre Gestor(a)',
    },
  ];

  // Lista de: Provincias, Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      movimiento: 9,
      numero: '',
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  // eslint-disable-next-line prefer-const
  let startMovimientos = [
    { itemLabel: '- SELECCIONE EL MOVIMIENTO -', value: 9 },
  ];
  const [movimientos, setMovimientos] = useState(
    startMovimientos,
  );

  useEffect(() => {
    setMovimientos(
      Lodash.get(
        listMovimientos,
        ['data', 'frmOptions'],
        [
          {
            itemLabel: '- SELECCIONE EL MOVIMIENTO -',
            value: 9,
          },
        ],
      ),
    );
  }, [listMovimientos]);

  const validationSchema = Yup.object({});

  const setProvincia = p => {
    initialValues.provincia = p;
    initialValues.movimiento = 9;
    setMovimientos([
      {
        itemLabel: '- SELECCIONE EL MOVIMIENTO -',
        value: 9,
      },
    ]);
    setSMovimiento(
      Lodash.find(movimientos, {
        value: 9,
      }),
    );
    if (p !== 9) {
      setSProvincia(
        Lodash.find(provincias, {
          value: p,
        }),
      );
    } else {
      setSProvincia(9);
    }
  };

  const setMovimiento = d => {
    initialValues.provincia = sProvincia.value
      ? sProvincia.value
      : 9;
    initialValues.movimiento = d;
    if (d !== 9) {
      setSMovimiento(
        Lodash.find(movimientos, {
          value: d,
        }),
      );
    } else {
      setSMovimiento(
        Lodash.find(movimientos, {
          value: 9,
        }),
      );
    }
  };

  const doSearch = values => () => {
    setShowResults(true);
    setIsLoading(true);
    const { movimiento, numero, provincia } = values;
    const codProvincia = provincia === 9 ? -1 : provincia;
    const codMovimiento =
      movimiento === 9 ? -1 : movimiento;
    const numPapeleta = numero === '' ? -1 : numero;
    searchPapeletas(
      codProvincia,
      codMovimiento,
      numPapeleta,
    ).then(result => {
      setIsLoading(false);
      const info = Lodash.get(
        result,
        ['data', 'response'],
        [],
      );

      if (info instanceof Object && info.length > 0) {
        const rowItems = info.map(row => {
          return createData(row);
        });
        setFilteredItems(rowItems);
        setDataEmpty(false);
      } else {
        setFilteredItems([]);
        setDataEmpty(true);
      }
    });
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h4>Provincia</h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <h4>Movimiento</h4>
            <Field
              name="movimiento"
              data={movimientos}
              component={PlnDropdown}
              onChange={movimiento =>
                setMovimiento(movimiento)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <h4>Número</h4>
            <Field
              mask="999999999"
              name="numero"
              component={PlnInput}
              type="text"
              style={{ marginTop: 10, height: 60 }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const consultar = (
    provincia,
    movimiento,
    numero,
    codPapeleta,
  ) => {
    const newRow = {
      ...rowSelected,
      provincia,
      movimiento,
      numero,
    };

    getDetallePapeleta(codPapeleta).then(result => {
      setRowSelected(newRow);

      const nComite = Lodash.get(
        result,
        ['data', 'response', 'comite'],
        [],
      );

      setComite(nComite);
      setOpenPapeleta(true);
    });
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    const actionConsultar = {
      aria: 'Consultar',
      key: 'consultar',
      showAction: true,
      icon: (
        <VisibilityIcon
          fontSize="default"
          style={{ color: '#008945' }}
        />
      ),
      clickHandler: _item => {
        consultar(
          _item.province,
          _item.movimiento,
          _item.numero,
          _item.cod_papeleta,
        );
      },
    };

    actions.push(actionConsultar);

    return actions;
  };

  const renderTable = () => {
    return (
      <div>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          className={classes.sectionTitle}
          style={{
            textAlign: 'center',
            marginTop: 15,
          }}
        >
          Candidatos(as) Representante Provincial
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>Lugar</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Cédula</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Nombre</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comite.map(l => (
              <TableRow key={`delegado-${l[0]}`}>
                <TableCell align="center">
                  <strong>{l[0]}</strong>
                </TableCell>
                <TableCell align="center">{l[1]}</TableCell>
                <TableCell align="center">{l[2]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>CONSULTA PÚBLICA DE INSCRIPCIONES</h3>
        <h4 className={classes.subtitle}>
          Papeletas Movimientos Provinciales
        </h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formik => (
            <>
              {renderForm()}
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={doSearch(formik.values)}
                    style={{ marginTop: 10 }}
                  >
                    BUSCAR
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>

        {showResults &&
          (!isLoading ? (
            <MacroList
              dataEmpty={isDataEmpty}
              listName=""
              uiStylesClasses={classes}
              btnConfig={{
                name: '+ Registro',
                event: () =>
                  (window.location.href = `/provinciales/add`),
              }}
              actionConfig={setActions}
              tableHeads={heads}
              tableBody={filteredItems}
              displayCopyright={false}
              isCrudAvailable={false}
            />
          ) : (
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center' }}
              >
                <CircularProgress
                  style={{
                    width: '28px',
                    height: '24px',
                  }}
                />
              </Grid>
            </Grid>
          ))}

        <Dialog
          disableBackdropClick
          fullWidth
          maxWidth="lg"
          open={openPapeleta}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            color="warning"
            style={{ textAlign: 'center' }}
          >
            Detalle Papeleta Movimiento Provincial
          </DialogTitle>
          <DialogContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <strong>Provincia</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Movimiento</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Número</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {rowSelected.provincia}
                  </TableCell>
                  <TableCell align="center">
                    {rowSelected.movimiento}
                  </TableCell>
                  <TableCell align="center">
                    {rowSelected.numero}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {renderTable()}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              className={classes.btnActions}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    );
  }
  return '';
};

CPPapeletasProvinciales.propTypes = {};

export default withStyles(style)(CPPapeletasProvinciales);
