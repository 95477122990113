const REGEX_UTILS = {
  cedula: /^[0-9]{9}$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  phone: /^[0-9]{8}$/,
  digits: /^[0-9]+$/,
  nickname: /^[a-z0-9~`!@#$%^&|\*()_={}[\]:;,.<>+\/?-]{6,}$/i,
  // eslint-disable-next-line max-len
  password: /^((?!.*[\s])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,})$/,
};

export default REGEX_UTILS;
