import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import style from '../Index/styles';
import Routes from '../../Routes/routes';
import CustomRoute from '../../Routes/CustomRoute';

const Layout = () => {
  return (
    <BrowserRouter>
      <Container maxWidth={false}>
        <Switch>
          {Routes.map(page => {
            return (
              <CustomRoute
                exact={page.exact}
                strict={page.strict}
                path={page.path}
                auth={page.auth}
                key={page.name}
                component={page.component}
                options={page.options}
              />
            );
          })}
        </Switch>
      </Container>
    </BrowserRouter>
  );
};

export default withStyles(style)(Layout);
