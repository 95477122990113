/* eslint-disable radix */
import Lodash from 'lodash';
import { addPrevencion } from '../Api/prevencionApi';
import balanceGlobalApi from '../Api/balanceGlobalApi';
import { generatePrevencion } from './Comprobante';

// eslint-disable-next-line import/prefer-default-export
export const startPrevencion = async (
  user,
  nCodPapeleta,
  nConsecutivo,
  indProceso,
  title,
  fechaPapeleta,
  gestorInfo,
) => {
  await balanceGlobalApi
    .getBalanceById(user.num_cedula)
    .then(bResult => {
      const currentSaldo = Lodash.get(
        bResult,
        ['data', 'response', 'saldo'],
        0,
      );

      if (parseInt(currentSaldo) < 0) {
        // Inicia la Prevencion
        const dataPrevencion = {
          num_cedula: user.num_cedula,
          cod_papeleta: nCodPapeleta,
          con_papeleta: nConsecutivo,
          ind_proceso: indProceso,
          ind_estado: 1,
          usuario: user.des_login,
        };

        addPrevencion({ dataPrevencion }).then(pResult => {
          const idPrevencion = Lodash.get(
            pResult,
            ['data', 'response'],
            0,
          );
          if (idPrevencion > 0) {
            // Se genera el comprobante de prevencion
            const dataPapeleta = {
              title,
              consecutivo: nConsecutivo,
              fecha: fechaPapeleta,
            };
            generatePrevencion(
              idPrevencion,
              dataPapeleta,
              gestorInfo,
            );
          }
        });
      }
    });
};
