import React, { useState, useEffect } from 'react';
import Lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useJwt } from 'react-jwt';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';
import sectorialesApi from '../../Core/Api/sectorialesApi';
import { getDataComprobante } from '../../Core/Api/comprobanteApi';
import Storage from '../../Storage/Storage';
import Consts from '../../Core/Consts/Consts';
import Filters from '../../Components/Filters/RegistrosElectFilter';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';

import { generateComprobanteSectorial } from '../../Core/Utils/Comprobante';
import { useCRUDAvailable } from '../../Core/Api/periodoApi';

function createData(_data) {
  const filterEstado = Consts.estadosDistritales.filter(
    status => {
      return status.codigo === _data.cod_estado;
    },
  );
  const filterSector = Consts.movimientosSectoriales.filter(
    status => {
      return status.codigo === _data.ind_sector;
    },
  );

  return {
    consecutivo: _data.num_consecutivo,
    sector: filterSector[0].sector,
    numero: _data.numero,
    cod_estado: filterEstado[0].estado,
    cod_papeleta: _data.cod_papeleta,
  };
}

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const Sectoriales = () => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );
  const classes = useStyles();

  const tokenStorage = Storage.instance;

  const [user, setUser] = useState([]);
  const [items, setItems] = useState([]);
  const [isDataEmpty, setDataEmpty] = useState(false);

  const [filters, setFilters] = React.useState({
    sector: '',
  });

  const { decodedToken } = useJwt(tokenStorage.getToken());
  const [filteredItems, setFilteredItems] = useState([]); // used for filtering

  const [open, setOpenDialog] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false); // used on remove modal
  const [removedMsj, setRemoveMsj] = useState(
    'La Papeleta será anulada. ¿Desea proceder?',
  );
  const [dataRemove, setDataRemove] = React.useState(null);
  const [hiddenBtn, setHiddenBtn] = useState(false);

  const getFilterKeys = Object.getOwnPropertyNames(filters);
  const TIMING_ON_SEARCH = 900;

  /**
   * Filter Items
   */
  const applyFilters = async () => {
    const activeFilters = getFilterKeys.filter(
      filter => filters[filter] !== '',
    );

    setDataEmpty(true);

    if (activeFilters.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const dataFiltered = activeFilters.map(myFilter => {
        const result = items.filter(_item => {
          let currentFilter = _item[myFilter];
          if (typeof currentFilter === 'number')
            currentFilter = currentFilter.toString();

          return currentFilter
            .toLowerCase()
            .includes(filters[myFilter].toLowerCase());
        });

        setDataEmpty(!result.length > 0);
        if (result.length > 0) setFilteredItems(result);
        return result;
      });
    } else {
      setFilteredItems(items);
      setDataEmpty(false);
    }
  };

  useEffect(() => {
    async function fetchListAPI() {
      if (decodedToken) {
        await sectorialesApi
          .getSectoriales(decodedToken.num_cedula)
          .then(_response => {
            const info = _response.data.response;

            if (info instanceof Object) {
              const rowItems = info.map(row => {
                return createData(row);
              });

              setItems(rowItems);
              setFilteredItems(rowItems);
              setDataEmpty(false);
            } else {
              setDataEmpty(true);
            }
          })
          .catch(_error => {
            // eslint-disable-next-line no-console
            console.log(_error);
          });
      }
    }

    fetchListAPI();
  }, [decodedToken]);

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  const printComprobante = codPapeleta => {
    getDataComprobante({
      codPapeleta,
      type: 5,
    }).then(r => {
      const comprobanteData = Lodash.get(
        r,
        ['data', 'response'],
        [],
      );

      // Informacion General
      const { generalInfo } = comprobanteData;

      // Informacion Gestor
      const gestorInfo = {
        cedula: user.num_cedula,
        nombre: user.des_nombre,
      };

      // Informacion Candidatos
      const candidatosInfo = {
        comite: comprobanteData.comite,
      };
      const montoInfo = {
        papeleta: comprobanteData.montosInfo.papeleta,
        membresia: comprobanteData.montosInfo.membresia,
        total: comprobanteData.montosInfo.total,
      };

      generateComprobanteSectorial(
        'ASAMBLEAS DISTRITALES',
        generalInfo,
        candidatosInfo,
        gestorInfo,
        montoInfo,
      );
    });
  };

  const listFilters = () => {
    return [
      {
        label: 'Sector:',
        action: async ev => {
          const updatedValue = {
            sector: ev.currentTarget.value,
          };
          await setFilters({
            ...filters,
            ...updatedValue,
          });
        },
        value: filters.sector,
        inputId: 'filter-sector',
        responsiveSize: {
          xs: 6,
          sm: 3,
        },
      },
    ];
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    if (crudAvailable) {
      const actionEdit = {
        aria: 'Editar Sectorial',
        key: 'edit',
        showAction: true,
        icon: (
          <EditIcon
            fontSize="inherit"
            style={{ color: '#84BF48' }}
          />
        ),
        clickHandler: _item => {
          window.location.href = `/sectoriales/edit/${_item.cod_papeleta}`;
        },
      };

      actions.push(actionEdit);

      const actionDecline = {
        aria: 'Anular Sectorial',
        key: 'remove',
        showAction: true,
        icon: (
          <BlockIcon
            fontSize="inherit"
            style={{ color: '#cc0000' }}
          />
        ),
        clickHandler: _item => {
          setDataRemove(_item);
          setOpenDialog(true);
          const msjReplacement = removedMsj.replace(
            '###',
            _item.id,
          );
          setRemoveMsj(msjReplacement);
        },
      };

      actions.push(actionDecline);
    }

    const actionPrintComprobante = {
      aria: 'Imprimir Comprobante',
      key: 'imprimir',
      showAction: true,
      icon: (
        <PrintIcon
          fontSize="inherit"
          style={{ color: '#666' }}
        />
      ),
      clickHandler: _item => {
        printComprobante(_item.cod_papeleta);
      },
    };

    actions.push(actionPrintComprobante);

    return actions;
  };

  /**
   * Anular papeleta
   */
  const declineSectorialesByNum = async () => {
    const currentCodPapeleta = dataRemove.cod_papeleta;

    if (currentCodPapeleta && currentCodPapeleta > 0) {
      setIsRemoved(true);

      await sectorialesApi
        .actionDeclinePapeleta({
          codPapeleta: currentCodPapeleta,
          cedulaGestor: decodedToken.num_cedula,
          consecutivo: dataRemove.consecutivo,
        })
        .then(_response => {
          const info = _response.data.response;

          if (info === 1 && _response.status === 200) {
            setIsRemoved(false);
            setHiddenBtn(true);
            setRemoveMsj(
              `Papeleta: ${currentCodPapeleta} anulada correctamente.`,
            );
            setTimeout(() => {
              setOpenDialog(false);
              window.location.href = '/sectoriales';
            }, 3000);
          } else {
            setHiddenBtn(false);
            setRemoveMsj(
              'La operación no se pudo completar. Intente de nuevo.',
            );
          }
        });
    }
  };

  useEffect(() => {
    async function proceedFilters() {
      await applyFilters();
    }

    const timeoutId = setTimeout(() => {
      if (filteredItems.length > 0) proceedFilters();
    }, TIMING_ON_SEARCH);
    return () => clearTimeout(timeoutId);
  }, [filters]);

  return (
    <React.Fragment>
      <MacroList
        filters={<Filters config={listFilters()} />}
        dataEmpty={isDataEmpty}
        listName="Lista de Papeletas Sectoriales"
        uiStylesClasses={classes}
        btnConfig={{
          actionKey: 'id',
          name: '+ Registro',
          event: () =>
            (window.location.href = `/sectoriales/add`),
        }}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={filteredItems}
        isCrudAvailable={crudAvailable}
      />
      <PLNDialog
        open={open}
        onClose={() => {}}
        title="Anular Papeleta"
        bodymessage={removedMsj}
        buttons={
          hiddenBtn
            ? []
            : [
                {
                  actionName: 'cancel',
                  color: 'simple',
                  title: 'Cerrar',
                  clickHander: () => {
                    setOpenDialog(!open);
                  },
                },
                {
                  actionName: 'delete',
                  disabled: isRemoved,
                  color: 'remove',
                  title: isRemoved ? (
                    <CircularProgress
                      style={{
                        width: '28px',
                        height: '24px',
                      }}
                    />
                  ) : (
                    'Anular'
                  ),
                  clickHander: declineSectorialesByNum,
                },
              ]
        }
      />
    </React.Fragment>
  );
};

export default Sectoriales;
