import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import style from './styles';

const PlnError = withStyles(style)(
  ({ classes, children }) => (
    <div className={classes.error} data-type="error">
      {children}
    </div>
  ),
);

PlnError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PlnError;
