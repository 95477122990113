function createData(
  provincia,
  canton,
  distrito,
  jrv,
  puesto,
  cedula,
  primerApellido,
  segundoApellido,
  nombre,
  id,
) {
  return {
    provincia,
    canton,
    distrito,
    jrv,
    puesto,
    cedula,
    primerApellido,
    segundoApellido,
    nombre,
    id,
  };
}

export const tableHeaders = [
  {
    name: 'Provincia',
  },
  {
    name: 'Cantón',
  },
  {
    name: 'Distrito Electoral',
  },
  {
    name: 'JRV',
  },
  {
    name: 'Puesto',
  },
  {
    name: 'Cédula',
  },
  {
    name: '1er. Apellido',
  },
  {
    name: '2do. Apellido',
  },
  {
    name: 'Nombre',
  },
];

export const dataRows = [
  createData(
    'HEREDIA',
    'BARVA',
    'SAN PABLO',
    '1453',
    'M.SUPLENTE',
    '401920238',
    'RAMIREZ',
    'RUIZ',
    'ESTEBAN',
    5334,
  ),
  createData(
    'HEREDIA',
    'SAN PABLO',
    'SABANILLA',
    '1454',
    'M.PROPIETARIO',
    '401940767',
    'ZUNIGA',
    'RUIZ',
    'JOSE',
    5326,
  ),
];
