/* eslint-disable class-methods-use-this */
const ConfigPageSymbol = Symbol('empty');
const ConfigPageSymbolEnforce = Symbol('empty');

class ConfigPage {
  constructor(enforcer) {
    if (enforcer !== ConfigPageSymbolEnforce) {
      throw new Error('Cannot construct singleton');
    }
  }

  static get instance() {
    if (!this[ConfigPageSymbol]) {
      this[ConfigPageSymbol] = new ConfigPage(
        ConfigPageSymbolEnforce,
      );
    }
    return this[ConfigPageSymbol];
  }

  setStorage(data) {
    localStorage.setItem(
      'config',
      typeof data === 'object'
        ? JSON.stringify(data)
        : { config: null },
    );
  }

  getStorage() {
    return JSON.parse(localStorage.getItem('config'));
  }

  removeStorage() {
    localStorage.removeItem('config');
  }
}

export default ConfigPage;
