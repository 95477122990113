import React from 'react';

const BalanceContext = React.createContext({
  deposits: 0,
  memberships: 0,
  inscriptions: 0,
  saldo: 0,
});

export const BalanceProvider = BalanceContext.Provider;
export const BalanceConsumer = BalanceContext.Consumer;

export default BalanceContext;
