import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid, Button, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { formatDate } from '../../Core/Utils/Utils';
import style from './styles';

import {
  PlnDropdown,
  PlnInput,
} from '../../Components/Input';
import Loading from '../../Components/Loading/Loading';

import Copyright from '../Copyright/Copyright';
import ListSearch from '../../Components/ListSearch/ListSearch';

import Consts from '../../Core/Consts/Consts';
import Storage from '../../Storage/Storage';
import REGEX from '../../Core/Utils/RegExpUtils';

import {
  getDepositInfoByCod,
  updateDeposito,
} from '../../Core/Api/depositosApi';

import { useCRUDAvailable } from '../../Core/Api/periodoApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const EditDeposito = props => {
  // Se valida si esta habilitado CRUD
  const isCRUDAvailable = useCRUDAvailable();
  const crudAvailable = Lodash.get(
    isCRUDAvailable,
    ['data', 'response'],
    null,
  );

  const { classes } = props;
  const history = useHistory();
  const { id } = useParams();

  const [user, setUser] = useState([]);

  const [dateField, setDateField] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const [isLoadingData, setLoading] = useState(true);
  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [finalDialog, setFinalDialog] = React.useState(
    false,
  );

  const defaultDepositData = {
    tipoDeposito: 9,
    depositante: '',
    numDeposito: '',
    dateDeposito: null,
    montoDeposito: '',
  };

  const [depositInfo, setDepositInfo] = useState(
    defaultDepositData,
  );

  const [initialValues] = useState({
    tipoDeposito: 9,
    depositante: '',
    numDeposito: '',
    dateDeposito: null,
    montoDeposito: '',
  });

  const [showForm] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [sTipoDeposito, setSTipoDeposito] = useState(9);

  // eslint-disable-next-line no-unused-vars
  const [listAddDeposito, setListDeposito] = useState(
    Consts.initListAddDeposito,
  );

  // eslint-disable-next-line no-unused-vars
  const [tiposDepositos, setTiposDeposito] = useState([
    { itemLabel: '- TIPO DEPOSITO -', value: 9 },
    { itemLabel: 'Membresías', value: 1 },
    { itemLabel: 'Inscripciones', value: 2 },
  ]);

  const handleClose = () => {
    setOpenDialog(false);
    setIsSaving(false);
  };

  const closeFinalDialog = () => {
    history.push('/registro-depositos');
  };

  const setTipoDeposito = d => {
    initialValues.tipoDeposito = d;
    if (d !== 9) {
      setSTipoDeposito(
        Lodash.find(tiposDepositos, {
          value: d,
        }),
      );
    }
  };

  const loadDepositDetails = async _codDeposito => {
    try {
      let dataSet = {};
      if (_codDeposito) {
        const info = await getDepositInfoByCod(
          _codDeposito,
        );

        const data = info.data.response;

        if (data instanceof Array && info.status === 200) {
          dataSet = {
            depositante: data[0].num_cedula,
            nombreDepositante: `${data[0].des_nombre} ${data[0].des_apell1} ${data[0].des_apell2}`,
            numDeposito: parseInt(data[0].num_deposito, 10),
            montoDeposito: parseInt(data[0].num_monto, 10),
            dateDeposito: new Date(data[0].fec_deposito),
            tipoDeposito:
              parseInt(data[0].ind_deposito, 10) + 1,
          };
        } else {
          dataSet = defaultDepositData;
        }
      } else {
        dataSet = defaultDepositData;
      }
      setDateField(dataSet.dateDeposito);
      setDepositInfo(dataSet);
      setTipoDeposito(dataSet.tipoDeposito);

      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  // Date Options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: 'UTC',
  };

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  useEffect(() => {
    async function fetchAPI() {
      const codDeposito = parseInt(id, 10);

      if (codDeposito < 0) {
        history.push('/registro-depositos');
      } else {
        setLoading(true);
        await loadDepositDetails(codDeposito);
      }
    }

    fetchAPI();
  }, []);

  const validationSchema = Yup.object({});

  const formikValidate = values => {
    const errors = {};
    const {
      numDeposito,
      montoDeposito,
      dateDeposito,
      depositante,
    } = values;

    if (!depositante) {
      errors.depositante = 'Digite una cédula válida.';
    } else if (!REGEX.cedula.test(depositante)) {
      errors.depositante = 'Formato incorrecto';
    }

    if (!dateDeposito) {
      errors.dateDeposito = 'Defina la fecha';
    }

    if (!numDeposito || parseInt(numDeposito, 10) === 0) {
      errors.numDeposito = 'Digite un número';
    } else if (!REGEX.digits.test(numDeposito)) {
      errors.numDeposito = 'Sólo se permiten números';
    }

    if (!montoDeposito || parseInt(numDeposito, 10) === 0) {
      errors.montoDeposito = 'Digite un monto';
    } else if (!REGEX.digits.test(montoDeposito)) {
      errors.montoDeposito = 'Sólo se permiten números';
    }

    return errors;
  };

  const saveForm = (values, { setSubmitting }) => {
    setSubmitting(false);
    const {
      depositante,
      montoDeposito,
      numDeposito,
      tipoDeposito,
      dateDeposito,
    } = values;

    const depositoData = {
      cod_deposito: id,
      ced_responsable: user.num_cedula,
      num_deposito: parseInt(numDeposito, 10),
      num_monto: parseInt(montoDeposito, 10),
      ced_depositante: depositante,
      des_usuario: user.des_login,
      ind_deposito: tipoDeposito - 1,
      fec_deposito: formatDate(
        dateDeposito.toLocaleDateString('es-ES', options),
      ),
    };

    updateDeposito({ depositoData })
      .then(_rs => {
        const { data, status } = _rs;

        if (data.response.status && status === 200) {
          setSubmitting(false);
          setMsjDialog(
            `El Depósito # ${id}. ha sido actualizado.`,
          );
          setFinalDialog(true);
        } else {
          setMsjDialog(
            'Error al intentar actualizar el depósito. Intente de nuevo!.',
          );
          setSubmitting(false);
          setOpenDialog(true);
        }
      })
      // eslint-disable-next-line no-console
      .catch(_err => console.log(_err));
  };

  return (
    <>
      {crudAvailable ? (
        <Container
          maxWidth="lg"
          className={classes.container}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  Editar Depósito
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.btnBack}
                    onClick={() => {
                      history.push('/registro-depositos');
                    }}
                  >
                    <ArrowBackIcon /> Regresar
                  </Button>
                </Typography>
                {!isLoadingData ? (
                  <Formik
                    validate={formikValidate}
                    initialValues={depositInfo}
                    validationSchema={validationSchema}
                    onSubmit={saveForm}
                  >
                    {formik => (
                      <>
                        <form
                          onSubmit={formik.handleSubmit}
                        >
                          <Divider />

                          {showForm ? (
                            <div>
                              <Grid
                                container
                                spacing={3}
                                style={{ display: 'flex' }}
                              >
                                <Grid item xs={12} sm={3}>
                                  <h4>Tipo Depósito:</h4>
                                  <Field
                                    name="tipoDeposito"
                                    data={tiposDepositos}
                                    component={PlnDropdown}
                                    onChange={tipo =>
                                      setTipoDeposito(tipo)
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Divider />
                              <Typography
                                component="h2"
                                variant="h6"
                                color="primary"
                                gutterBottom
                                className={
                                  classes.sectionTitle
                                }
                              >
                                Datos del Depósito
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                  <h4
                                    className={
                                      classes.fieldTitle
                                    }
                                  >
                                    Fecha del Depósito
                                  </h4>
                                  <div
                                    className={
                                      classes.fieldFilled
                                    }
                                  >
                                    <DatePicker
                                      className={
                                        classes.calendarInput
                                      }
                                      placeholderText="Seleccione una fecha"
                                      name="dateDeposito"
                                      closeOnScroll
                                      dateFormat="dd/MM/yyyy"
                                      selected={dateField}
                                      onChange={date => {
                                        setDateField(date);
                                        formik.setFieldValue(
                                          'dateDeposito',
                                          date,
                                        );
                                      }}
                                    />
                                  </div>
                                  {formik.errors
                                    .dateDeposito &&
                                  formik.touched
                                    .dateDeposito ? (
                                    <div
                                      className={
                                        classes.formError
                                      }
                                    >
                                      <Alert severity="error">
                                        {
                                          formik.errors
                                            .dateDeposito
                                        }
                                      </Alert>
                                    </div>
                                  ) : null}
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <h4
                                    className={
                                      classes.fieldTitle
                                    }
                                  >
                                    Cédula Gestor
                                  </h4>
                                  <div
                                    className={
                                      classes.fieldFilled
                                    }
                                  >
                                    {user.num_cedula}
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <h4
                                    className={
                                      classes.fieldTitle
                                    }
                                  >
                                    Nombre del Gestor
                                  </h4>
                                  <div
                                    className={
                                      classes.fieldFilled
                                    }
                                  >
                                    {user.des_nombre}
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <h4
                                    className={
                                      classes.fieldTitle
                                    }
                                  >
                                    Número del Depósito
                                  </h4>
                                  <Field
                                    id="numDeposito"
                                    name="numDeposito"
                                    component={PlnInput}
                                    className={
                                      classes.frmText
                                    }
                                    data={
                                      depositInfo.numDeposito
                                    }
                                  />
                                  {formik.errors
                                    .numDeposito &&
                                  formik.touched
                                    .numDeposito ? (
                                    <div
                                      className={
                                        classes.formError
                                      }
                                    >
                                      <Alert severity="error">
                                        {
                                          formik.errors
                                            .numDeposito
                                        }
                                      </Alert>
                                    </div>
                                  ) : null}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <h4
                                    className={
                                      classes.fieldTitle
                                    }
                                  >
                                    Monto del Depósito
                                  </h4>
                                  <Field
                                    id="montoDeposito"
                                    name="montoDeposito"
                                    component={PlnInput}
                                    className={
                                      classes.frmText
                                    }
                                    data={
                                      depositInfo.montoDeposito
                                    }
                                  />
                                  {formik.errors
                                    .montoDeposito &&
                                  formik.touched
                                    .montoDeposito ? (
                                    <div
                                      className={
                                        classes.formError
                                      }
                                    >
                                      <Alert severity="error">
                                        {
                                          formik.errors
                                            .montoDeposito
                                        }
                                      </Alert>
                                    </div>
                                  ) : null}
                                </Grid>
                              </Grid>

                              <ListSearch
                                title="Información del Depositante"
                                formik={formik}
                                initListData={[
                                  {
                                    id: 1,
                                    lugar: 1,
                                    numPuesto: 1,
                                    fieldName:
                                      'depositante',
                                    cedula:
                                      depositInfo.depositante,
                                    nombre:
                                      depositInfo.nombreDepositante,
                                    enabled: true,
                                    filled: true,
                                  },
                                ]}
                                sexOrderM={
                                  Consts.comiteNacionalMOrder
                                }
                                sexOrderF={
                                  Consts.comiteNacionalFOrder
                                }
                                hideSexColumn
                                fieldToShow={1}
                                // eslint-disable-next-line radix
                                inPlace={1}
                                setListFunc={
                                  setListDeposito
                                }
                                validateBy={[
                                  {
                                    dataVal: 'none',
                                    value: 1,
                                  },
                                ]}
                                needHojaInfo={false}
                              />
                              {formik.errors.depositante &&
                              formik.touched.depositante ? (
                                <div
                                  className={
                                    classes.formError
                                  }
                                >
                                  <Alert severity="error">
                                    {
                                      formik.errors
                                        .depositante
                                    }
                                  </Alert>
                                </div>
                              ) : null}

                              <Grid
                                container
                                spacing={3}
                                style={{ marginTop: 40 }}
                              >
                                <Grid item xs={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={
                                      formik.isSubmitting ||
                                      isSaving
                                        ? classes.btnSubmitting
                                        : classes.btnActions
                                    }
                                    disabled={
                                      formik.isSubmitting ||
                                      isSaving
                                    }
                                  >
                                    {formik.isSubmitting ? (
                                      <CircularProgress
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                        }}
                                      />
                                    ) : (
                                      <>
                                        Guardar
                                        <SaveIcon
                                          className={
                                            classes.frmIconButton
                                          }
                                        />
                                      </>
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            ''
                          )}
                        </form>
                      </>
                    )}
                  </Formik>
                ) : (
                  <Loading label="Cargando Informacion" />
                )}
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    id="alert-confirm-messages"
                    color="warning"
                  >
                    Mensaje
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {msjDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="primary"
                    >
                      Entendido
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={finalDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={closeFinalDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    id="alert-finalDialog"
                    color="warning"
                  >
                    Mensaje
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {msjDialog}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={closeFinalDialog}
                      color="primary"
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          className={classes.container}
          style={{
            textAlign: 'center',
          }}
        >
          <Grid
            container
            spacing={3}
            style={{ marginTop: 20 }}
          >
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                backgroundColor: '#FFE599',
                color: '#FF9326',
              }}
            >
              <h4>
                Esta sección se encuentra temporalmente
                deshabilitada. <br />
                Favor comunicarse con Ezequiel Hernández al
                2519-5521 o al correo
                ehernandez@plndigital.com
              </h4>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default withStyles(style)(EditDeposito);
