function createData(
  tipo,
  consecutivo,
  cedula,
  candidato,
  monto,
  registro,
  last,
) {
  return {
    tipo,
    consecutivo,
    cedula,
    candidato,
    monto,
    registro,
    last,
  };
}

export const tableHeaders = [
  {
    name: 'Tipo',
  },
  {
    name: 'Consecutivo',
  },
  {
    name: 'Cedula',
  },
  {
    name: 'Gestor/Candidato',
  },
  {
    name: 'Monto',
  },
  {
    name: 'Registro',
  },
];

export const dataRows = [
  createData(
    'Distrital',
    '1',
    '111140239',
    'Chistopher Barrantes Bolaños',
    '35000',
    '1/3/2021',
  ),
  createData(
    'Nacional',
    '1',
    '401920238',
    'Esteban Ramirez Ruiz',
    '35000',
    '1/3/2021',
  ),
];
